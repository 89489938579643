import styled from '@emotion/styled';
// style
import { media } from 'Styles/neptoTheme';

/** PC에서 보는 경우 띄워주는 Orb */
const Video = ({ src, width }: props) => {
  return (
    <Container>
      <StyledVideo src={src} autoPlay loop muted playsInline width={width ?? 612} />
    </Container>
  );
};

const Container = styled.div`
  display: none !important;
  position: relative;
  z-index: 1;
  width: 100%;
  flex: 1;

  ${media.lg} {
    position: relative !important;
    z-index: 1;
    flex: 1;
    top: 0;
    height: auto;

    display: flex !important;
  }
`;

const StyledVideo = styled.video`
  max-width: 480px;
  position: absolute;
  top: 50%;
  left: 50%;

  ${media.lg} {
    width: 612px;
    transform: translate(-50%, -50%);
  }
`;

interface props {
  src: string;
  width?: number;
}

export default Video;
