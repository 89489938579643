/* eslint-disable react/prop-types */
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
// components
import Font from 'Components/Atoms/Font';
// lib
import ImageAsset from 'Lib/Model/Asset/image';
// constants
import { nrns } from 'Constants/nrn';
// styles
import { media } from 'Styles/neptoTheme';
import Container from '../Atoms/Container';
import Layout from '../Common/Layout';
import { checkMobile } from '../../Util/checkMobile';

const CONTENTS = [
  {
    id: 3,
    title: 'Web3',
    text: 'Join communities based on tokens you own',
    img: ImageAsset.convertNrnToUri(nrns.CARD_WEB3, {
      format: 'webp',
      width: 754,
    }),
    color: 'linear-gradient(148deg, #003db2 0%, rgba(40, 0, 198, 0.54) 92.72%)',
    img_color: ImageAsset.convertNrnToUri(nrns.CARD_WEB3_COLOR, {
      format: 'webp',
      width: 754,
    }),
  },
  {
    id: 2,
    title: 'Tokengate',
    text: 'Enjoy exclusive spaces for verified holders only',
    img: ImageAsset.convertNrnToUri(nrns.CARD_TOKENGATE, {
      format: 'webp',
      width: 754,
    }),
    color: 'linear-gradient(216deg, #36007A -0.04%, #3D007A -0.03%, #3F00A6 100.04%);',
    img_color: ImageAsset.convertNrnToUri(nrns.CARD_TOKENGATE_COLOR, {
      format: 'webp',
      width: 754,
    }),
  },
  {
    id: 1,
    title: 'Vote',
    text: 'Participate in decision-making process as a community',
    img: ImageAsset.convertNrnToUri(nrns.CARD_VOTE, {
      format: 'webp',
      width: 754,
    }),
    color: 'linear-gradient(212deg, #6D00B0 -0.53%, rgba(41, 1, 126, 0.68) 98.96%)',
    img_color: ImageAsset.convertNrnToUri(nrns.CARD_VOTE_COLOR, {
      format: 'webp',
      width: 754,
    }),
  },
];

const Section8 = () => {
  const scrollContainerRef = useRef<(HTMLDivElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const [scrollY, setScrollY] = useState<number>(0);
  const [viewPortHegiht, setViewPortHeight] = useState<number>(0);
  const [isViewCardSection, setIsViewCardSection] = useState<boolean>(false);
  const [activateCardNumber, setActivateCardNumber] = useState<number>(0);
  const isMobile = checkMobile();
  const compareValue = (a: number, value1: number, value2: number, value3: number) => {
    const result = [Math.abs(a - value1), Math.abs(a - value2), Math.abs(a - value3)];
    const min = Math.min(...result);
    return result.indexOf(min);
  };

  const handleScroll = () => setScrollY(window.scrollY);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef?.current && scrollContainerRef?.current) {
        const top = containerRef.current.offsetTop;
        const height = containerRef.current.offsetHeight;
        const scrollY = window.scrollY;
        const windowHeight = window.innerHeight;
        const stickyStartPosition = windowHeight - height / 2;
        const offset = 106 + 330 + 72 + stickyStartPosition + height / 2;
        const ratio = (scrollY + windowHeight - (top + offset)) / (height - offset);

        switch (isMobile) {
          case true:
            if (ratio < 0) {
              setActivateCardNumber(3);
            } else if (ratio > 1) {
              setActivateCardNumber(1);
            } else {
              setActivateCardNumber(2);
            }
            break;

          default:
            if (ratio < 0) {
              setActivateCardNumber(3);
            } else if (ratio > 1) {
              setActivateCardNumber(1);
            } else {
              setActivateCardNumber(3 - Math.floor(ratio * 3));
            }
        }
        setScrollY(window.scrollY);
        setViewPortHeight(window.innerHeight);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useLayoutEffect(() => {
    const preloadImage = () => {
      CONTENTS.forEach(value => {
        const img = new Image();
        img.src = value.img_color;
      });
    };
    preloadImage();
    [];
  });

  return (
    <Layout
      ref={containerRef}
      css={css`
        height: auto;
        ${media.md} {
          height: 3000px;
        }
      `}
    >
      <Container
        containerCss={css`
          position: sticky;
          top: -80px;
          @media (min-height: 1200px) {
            top: 106px;
          }
          height: auto;
          padding-top: 240px;
        `}
      >
        <TextWrapper>
          <Font
            as="h2"
            type="Heading2"
            style={css`
              text-align: center;
              background: linear-gradient(267deg, #91a3ff 1.01%, #50eaff 101.3%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            `}
          >
            Community
          </Font>
          <br />
          <Font
            type="Heading1"
            as="h3"
            style={css`
              margin-bottom: 16px;
              text-align: center;
            `}
          >
            Experience contract-based <br /> communities on Nepto
          </Font>
          <Font
            type="Display4"
            as="div"
            style={css`
              text-align: center;
            `}
          >
            <Font type="Display4" textColor="white">
              Connect with people holding the same asset or similar interests
            </Font>
          </Font>
        </TextWrapper>
        <div
          css={css`
            display: flex;
            flex-direction: column !important;
            justify-content: center;
            align-items: center;
            gap: 16px;
            padding: 0 36px;
            ${media.sm} {
              img {
                padding: 0;
              }
            }
            ${media.md} {
              display: flex;
              flex-direction: row !important;
            }
            display: flex;
            gap: 16px;
          `}
        >
          {CONTENTS.map((value, index) => (
            <div key={index} ref={ref => (scrollContainerRef.current[index] = ref)}>
              <Card
                isTokenCard={value.id === 2}
                imgUrl={
                  isViewCardSection && activateCardNumber === value.id ? value.img_color : value.img
                }
                color={value.color}
                isActiveCard={activateCardNumber === value.id}
              >
                <Font type="Heading3" color="shade900">
                  {value.title}
                </Font>
                <Font type="Body" color="shade900">
                  {value.text}
                </Font>
              </Card>
            </div>
          ))}
        </div>
      </Container>
    </Layout>
  );
};

const TextWrapper = styled.div`
  z-index: 2;
  margin-bottom: 72px;
  padding: 0 24px;
`;

const Card = styled.div<{
  imgUrl: string;
  color: string;
  isActiveCard: boolean;
  isTokenCard: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 32px;
  border-radius: 24px;
  background: url(${({ imgUrl }) => imgUrl}), ${({ color }) => color};
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 390px;
  height: 336px;
  justify-content: flex-end;
  ${media.sm} {
    justify-content: flex-end;
  }
  ${media.md} {
    max-width: 390px;
    width: 100%;
    height: 366px;
    background-position: top;
  }
  ${media.lg} {
    background-position: top 20% top bottom;
    height: 284px;
  }
  filter: ${({ isActiveCard }) => (isActiveCard ? 'grayscale(0)' : 'grayscale(1) ')};
  transition: all 0.2s;
  @media screen and (max-height: 845px) and (min-width: 900px) {
    height: 228px;
    justify-content: flex-start;
    background-position: ${({ isTokenCard }) =>
      isTokenCard
        ? 'center 32px, center bottom !important'
        : 'center -70px, center bottom !important'};
    background-size: 100%;
  }
`;

export default Section8;
