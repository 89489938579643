import { FC } from 'react';
import { css, useTheme } from '@emotion/react';
import { motion } from 'framer-motion';
// components
import Font from 'Components/Atoms/Font';
import Container from 'Components/Atoms/Container';
import Layout from 'Components/Common/Layout';

const Section2: FC = () => {
  const { breakPoints } = useTheme();

  return (
    <Layout>
      <Container
        containerCss={css`
          padding: 160px 0 !important;
          z-index: 2;
          position: relative;
          display: flex;
          height: auto;
          text-align: center;
          @media (max-width: ${breakPoints.lg}px) {
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 160px 24px !important;
          }
          @media (max-width: ${breakPoints.md}px) {
            padding: 160px 16px !important;
          }
        `}
      >
        <span
          css={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          `}
        >
          {'Nepto enables anyone on blockchain to send message directly to wallet address'
            .split(' ')
            .map((item, index) => {
              return (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, ease: 'easeIn', delay: ((index + 1) * 50) / 1000 }}
                  viewport={{ once: true }}
                >
                  <Font type="Heading1" as="h2">
                    {item}&nbsp;
                  </Font>
                </motion.div>
              );
            })}
        </span>
      </Container>
    </Layout>
  );
};

export default Section2;
