import { css } from '@emotion/react';
import { media } from 'Styles/neptoTheme';

const heightMedia = `@media (max-height: 800px) and (min-width: 1200px)`;

export const inherit = css`
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  font-feature-settings: inherit;
`;

/* ==================== Title ==================== */

export const title1 = css`
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings:
    'cv09' on,
    'cv04' on,
    'cv03' on;

  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 42px */
  font-size: 20px;
  ${media.sm} {
    font-size: 24px;
  }
  ${media.md} {
    font-size: 28px;
  }
  ${heightMedia} {
    font-size: 24px;
  }
`;

export const title2 = css`
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings:
    'cv09' on,
    'cv04' on,
    'cv03' on;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 42px */
  font-size: 20px;
  ${media.sm} {
    font-size: 24px;
  }
  ${media.md} {
    font-size: 28px;
  }
  ${heightMedia} {
    font-size: 24px;
  }
`;

/* ==================== Heading ==================== */

export const heading1 = css`
  font-variant-numeric: lining-nums proportional-nums;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 80px */
  font-size: 26px;
  ${media.sm} {
    font-size: 40px;
  }
  ${media.md} {
    font-size: 48px;
  }
  ${media.lg} {
    font-size: 64px;
  }
  ${heightMedia} {
    font-size: 40px;
  }
`;

export const heading2 = css`
  font-feature-settings:
    'cv03' on,
    'cv09' on,
    'cv04' on;
  font-variant-numeric: lining-nums proportional-nums;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 60px */
  font-size: 26px;
  ${media.sm} {
    font-size: 38px;
  }
  ${media.md} {
    font-size: 48px;
  }
  ${heightMedia} {
    font-size: 38px;
  }
`;

export const heading3 = css`
  font-feature-settings:
    'cv03' on,
    'cv09' on,
    'cv04' on;
  font-variant-numeric: lining-nums proportional-nums;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 48px */
  font-size: 20px;
  ${media.sm} {
    font-size: 28px;
  }
  ${media.md} {
    font-size: 32px;
  }
  ${heightMedia} {
    font-size: 28px;
  }
`;

export const subheader = css`
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings:
    'cv03' on,
    'cv09' on,
    'cv04' on,
    'calt' off;
  /* Mobile/Subheader */
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 106.667% */
`;

/* ==================== Strong ==================== */

export const strong1 = css`
  font-style: normal;
  font-weight: 700;
  line-height: 135%; /* 32.4px */
  letter-spacing: 0.6px;
  text-transform: uppercase;
  font-size: 20px;
  ${media.sm} {
    font-size: 24px;
  }
  ${heightMedia} {
    font-size: 20px;
  }
`;

export const strong2 = css`
  font-style: normal;
  font-weight: 700;
  line-height: 135%; /* 27px */
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-size: 18px;
  ${media.sm} {
    font-size: 20px;
  }
  ${heightMedia} {
    font-size: 18px;
  }
`;

/* ==================== Body ==================== */

export const body = css`
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings:
    'cv09' on,
    'cv04' on,
    'cv03' on;

  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  font-size: 18px;
  ${media.sm} {
    font-size: 24px;
  }
  ${heightMedia} {
    font-size: 24px;
  }
`;

/* ==================== Caption ==================== */

export const caption1 = css`
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings:
    'cv09' on,
    'cv04' on,
    'cv03' on;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  font-size: 15px;
  ${media.sm} {
    font-size: 18px;
  }
`;

export const caption2 = css`
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings:
    'cv09' on,
    'cv04' on,
    'cv03' on;

  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
  font-size: 15px;
  ${media.sm} {
    font-size: 18px;
  }
`;

/* ==================== Button ==================== */

export const buttonBase = css`
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings:
    'cv09' on,
    'cv04' on,
    'cv03' on;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
  font-size: 18px;
  ${media.sm} {
    font-size: 20px;
  }
`;

/* ==================== Display ==================== */

export const display1 = css`
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 80px */
  font-size: 40px;
  ${media.sm} {
    font-size: 60px;
  }
  ${media.md} {
    font-size: 80px;
  }
  ${heightMedia} {
    font-size: 60px;
  }
`;

export const display2 = css`
  font-variant-numeric: lining-nums proportional-nums;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 108px */
  font-size: 40px;
  ${media.sm} {
    font-size: 60px;
  }
  ${media.md} {
    font-size: 80px;
  }
  ${heightMedia} {
    font-size: 60px;
  }
`;

export const display3 = css`
  font-variant-numeric: lining-nums proportional-nums;
  font-style: normal;
  font-weight: 600;
  line-height: 125%; /* 65px */
  font-size: 28px;
  ${media.sm} {
    font-size: 40px;
  }
  ${media.md} {
    font-size: 52px;
  }
  ${heightMedia} {
    font-size: 40px;
  }
`;

export const display4 = css`
  font-variant-numeric: lining-nums proportional-nums;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 48.6px */
  font-size: 18px;

  ${media.sm} {
    font-size: 24px;
  }
  ${media.md} {
    font-size: 28px;
  }
  ${media.lg} {
    font-size: 36px;
  }
  ${heightMedia} {
    font-size: 24px;
  }
`;
