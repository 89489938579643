import { ElementType, forwardRef, HTMLAttributes, memo, ReactNode, Ref } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
// style
import { ColorType, media } from 'Styles/neptoTheme';

// eslint-disable-next-line react/display-name
const Font = forwardRef(
  (
    {
      textColor = 'shade900',
      type,
      children,
      className,
      style,
      as,
      ...rest
    }: HTMLAttributes<HTMLSpanElement> & Props,
    ref?: Ref<HTMLSpanElement>,
  ) => {
    return (
      <NeptoFont
        as={as}
        ref={ref}
        css={style}
        className={className}
        $type={type}
        $color={textColor}
        {...rest}
      >
        {children}
      </NeptoFont>
    );
  },
);

const generateFontStyle = (type: FontType) => {
  const heightMedia = `@media (max-height: 800px) and (min-width: 1200px)`;

  switch (type) {
    case 'Display1':
      return css`
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 80px */
        font-size: 40px;
        ${media.sm} {
          font-size: 60px;
        }
        ${media.md} {
          font-size: 80px;
        }
        ${heightMedia} {
          font-size: 60px;
        }
      `;
    case 'Display2':
      return css`
        font-variant-numeric: lining-nums proportional-nums;
        font-style: normal;
        font-weight: 500;
        line-height: 135%; /* 108px */
        font-size: 40px;
        ${media.sm} {
          font-size: 60px;
        }
        ${media.md} {
          font-size: 80px;
        }
        ${heightMedia} {
          font-size: 60px;
        }
      `;
    case 'Display3':
      return css`
        font-variant-numeric: lining-nums proportional-nums;
        font-style: normal;
        font-weight: 600;
        line-height: 125%; /* 65px */
        font-size: 28px;
        ${media.sm} {
          font-size: 40px;
        }
        ${media.md} {
          font-size: 52px;
        }
        ${heightMedia} {
          font-size: 40px;
        }
      `;
    case 'Display4':
      return css`
        font-variant-numeric: lining-nums proportional-nums;
        font-style: normal;
        font-weight: 500;
        line-height: 135%; /* 48.6px */
        font-size: 18px;

        ${media.sm} {
          font-size: 24px;
        }
        ${media.md} {
          font-size: 28px;
        }
        ${media.lg} {
          font-size: 36px;
        }
        ${heightMedia} {
          font-size: 24px;
        }
      `;
    case 'Heading1':
      return css`
        font-variant-numeric: lining-nums proportional-nums;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 80px */
        font-size: 26px;
        ${media.sm} {
          font-size: 40px;
        }
        ${media.md} {
          font-size: 48px;
        }
        ${media.lg} {
          font-size: 64px;
        }
        ${heightMedia} {
          font-size: 40px;
        }
      `;
    case 'Heading2':
      return css`
        font-feature-settings:
          'cv03' on,
          'cv09' on,
          'cv04' on;
        font-variant-numeric: lining-nums proportional-nums;
        font-style: normal;
        font-weight: 700;
        line-height: 125%; /* 60px */
        font-size: 26px;
        ${media.sm} {
          font-size: 38px;
        }
        ${media.md} {
          font-size: 48px;
        }
        ${heightMedia} {
          font-size: 38px;
        }
      `;
    case 'Heading3':
      return css`
        font-feature-settings:
          'cv03' on,
          'cv09' on,
          'cv04' on;
        font-variant-numeric: lining-nums proportional-nums;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 48px */
        font-size: 20px;
        ${media.sm} {
          font-size: 28px;
        }
        ${media.md} {
          font-size: 32px;
        }
        ${heightMedia} {
          font-size: 28px;
        }
      `;
    case 'Title1':
      return css`
        font-variant-numeric: lining-nums proportional-nums;
        font-feature-settings:
          'cv09' on,
          'cv04' on,
          'cv03' on;

        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 42px */
        font-size: 20px;
        ${media.sm} {
          font-size: 24px;
        }
        ${media.md} {
          font-size: 28px;
        }
        ${heightMedia} {
          font-size: 24px;
        }
      `;
    case 'Title2':
      return css`
        font-variant-numeric: lining-nums proportional-nums;
        font-feature-settings:
          'cv09' on,
          'cv04' on,
          'cv03' on;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 42px */
        font-size: 20px;
        ${media.sm} {
          font-size: 24px;
        }
        ${media.md} {
          font-size: 28px;
        }
        ${heightMedia} {
          font-size: 24px;
        }
      `;
    case 'Strong1':
      return css`
        font-style: normal;
        font-weight: 700;
        line-height: 135%; /* 32.4px */
        letter-spacing: 0.6px;
        text-transform: uppercase;
        font-size: 20px;
        ${media.sm} {
          font-size: 24px;
        }
        ${heightMedia} {
          font-size: 20px;
        }
      `;
    case 'Strong2':
      return css`
        font-style: normal;
        font-weight: 700;
        line-height: 135%; /* 27px */
        letter-spacing: 0.5px;
        text-transform: uppercase;
        font-size: 18px;
        ${media.sm} {
          font-size: 20px;
        }
        ${heightMedia} {
          font-size: 18px;
        }
      `;
    case 'Body':
      return css`
        font-variant-numeric: lining-nums proportional-nums;
        font-feature-settings:
          'cv09' on,
          'cv04' on,
          'cv03' on;

        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 36px */
        font-size: 18px;
        ${media.sm} {
          font-size: 24px;
        }
        ${heightMedia} {
          font-size: 24px;
        }
      `;
    case 'Caption1':
      return css`
        font-variant-numeric: lining-nums proportional-nums;
        font-feature-settings:
          'cv09' on,
          'cv04' on,
          'cv03' on;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 27px */
        font-size: 15px;
        ${media.sm} {
          font-size: 18px;
        }
      `;
    case 'Caption2':
      return css`
        font-variant-numeric: lining-nums proportional-nums;
        font-feature-settings:
          'cv09' on,
          'cv04' on,
          'cv03' on;

        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 27px */
        font-size: 15px;
        ${media.sm} {
          font-size: 18px;
        }
      `;
    case 'Button':
      return css`
        font-variant-numeric: lining-nums proportional-nums;
        font-feature-settings:
          'cv09' on,
          'cv04' on,
          'cv03' on;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 30px */
        font-size: 18px;
        ${media.sm} {
          font-size: 20px;
        }
      `;
  }
};

const NeptoFont = styled.span<{ $color: ColorType; $hoverColor?: ColorType; $type: FontType }>`
  color: ${({ theme, $color }) => theme.colorTheme[$color]};
  ${({ $type }) => generateFontStyle($type)};

  &:hover {
    color: ${({ theme, $hoverColor, $color }) =>
      $hoverColor ? theme.colorTheme[$hoverColor] : theme.colorTheme[$color]};
  }
`;

type FontType =
  | 'Display1'
  | 'Display2'
  | 'Display3'
  | 'Display4'
  | 'Heading1'
  | 'Heading2'
  | 'Heading3'
  | 'Title1'
  | 'Title2'
  | 'Strong1'
  | 'Strong2'
  | 'Body'
  | 'Caption1'
  | 'Caption2'
  | 'Button';

interface Props {
  type: FontType;
  textColor?: ColorType;
  hoverColor?: ColorType;
  children: ReactNode;
  className?: string;
  style?: ReturnType<typeof css>;
  as?: ElementType<any>;
}

export default memo(Font);
