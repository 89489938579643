import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';
import { throttle } from 'lodash';
import { Link } from 'react-router-dom';
// component
import StoreButton from 'Components/Common/StoreButton';
// lib
import ImageAsset from 'Lib/Model/Asset/image';
import { onlyHover } from 'Lib/Model/style/onlyHover';
// constant
import { nrns } from 'Constants/nrn';
// style
import { media, mediaQuery } from 'Styles/neptoTheme';
// util
import { checkMobileType } from 'Util/checkMobile';
import DownloadButton from './DownloadButton';

const Header = () => {
  const { breakPoints } = useTheme();
  const [isScrollDown, setIsScrollDown] = useState(false);

  const handleScroll = throttle(() => {
    const { y } = document.body.getBoundingClientRect();
    const isScrollingDown = y < 0;
    setIsScrollDown(isScrollingDown);
  }, 300);

  useEffect(() => {
    // 페이지 이동 시 헤더를 다시 노출시킴
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <HeaderContainer>
      <Home href="/">
        <img
          src={ImageAsset.convertNrnToUri(nrns.NEPTO_LIGHT_DARK, {
            format: 'webp',
            height: 102,
          })}
          css={css`
            height: 40px;
            ${mediaQuery(breakPoints.sm)} {
              height: 56px;
            }
          `}
          alt="nepto-logo"
        />
      </Home>
      {/* TODO 넵토 출시 전까지 주석처리 */}
      {/*<ButtonWrapper $isScrollDown={isScrollDown} className="lg">*/}
      {/*  <StoreButton*/}
      {/*    storeType="apple"*/}
      {/*    nrn={nrns.APP_STORE_SYMBOL_LIGHT}*/}
      {/*    text="App Store"*/}
      {/*    style={css`*/}
      {/*      padding: 8px 24px;*/}
      {/*    `}*/}
      {/*  />*/}
      {/*  <StoreButton*/}
      {/*    storeType="google"*/}
      {/*    nrn={nrns.GOOGLE_PLAY_SYMBOL}*/}
      {/*    text="Google Play"*/}
      {/*    style={css`*/}
      {/*      padding: 8px 24px;*/}
      {/*    `}*/}
      {/*  />*/}
      {/*  /!*<DownloadButton />*!/*/}
      {/*</ButtonWrapper>*/}
      {/*<ButtonWrapper $isScrollDown={isScrollDown} className="md">*/}
      {/*  <MobileHeaderButton to="/" className={checkMobileType() === 'ios' ? 'visible' : ''}>*/}
      {/*    <img*/}
      {/*      src={ImageAsset.convertNrnToUri(nrns.APP_STORE_SYMBOL_LIGHT, {*/}
      {/*        format: 'webp',*/}
      {/*        width: 102,*/}
      {/*      })}*/}
      {/*      css={css`*/}
      {/*        width: 100%;*/}
      {/*      `}*/}
      {/*      alt="menu-symbol"*/}
      {/*    />*/}
      {/*  </MobileHeaderButton>*/}
      {/*  <MobileHeaderButton to="/" className={checkMobileType() === 'android' ? 'visible' : ''}>*/}
      {/*    <img*/}
      {/*      src={ImageAsset.convertNrnToUri(nrns.GOOGLE_PLAY_SYMBOL, {*/}
      {/*        format: 'webp',*/}
      {/*        width: 102,*/}
      {/*      })}*/}
      {/*      css={css`*/}
      {/*        width: 100%;*/}
      {/*      `}*/}
      {/*      alt="menu-symbol"*/}
      {/*    />*/}
      {/*  </MobileHeaderButton>*/}
      {/*</ButtonWrapper>*/}
    </HeaderContainer>
  );
};

const Home = styled.a`
  transition: all 0.3s ease;

  ${onlyHover({
    opacity: 0.68,
  })}

  :active {
    transform: scale(0.9);
  }
`;
const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  background-color: ${({ theme }) => theme.colorTheme.reverse54};
  transition: all 0.21s;
  ${media.sm} {
    padding: 24px;
  }
`;

const ButtonWrapper = styled.div<{ $isScrollDown?: boolean }>`
  display: flex;
  gap: 16px;
  opacity: ${({ $isScrollDown }) => ($isScrollDown ? 1 : 0)};
  visibility: ${({ $isScrollDown }) => ($isScrollDown ? 'visible' : 'hidden')};
  transition: all 0.21s;

  &.lg {
    ${({ theme }) => mediaQuery(theme.breakPoints.xs)} {
      display: none;
    }
    ${({ theme }) => mediaQuery(theme.breakPoints.lg)} {
      display: flex;
    }
  }

  &.md {
    ${({ theme }) => mediaQuery(theme.breakPoints.lg)} {
      display: none;
    }
  }
`;

const MobileHeaderButton = styled(Link)`
  width: 40px;
  height: 40px;
  display: none;
  padding: 6px;
  ${media.sm} {
    padding: 8px;
    width: 56px;
    height: 56px;
  }

  ${({ theme }) => mediaQuery(theme.breakPoints.xs)} {
    &.visible {
      display: block;
    }
  }
  ${({ theme }) => mediaQuery(theme.breakPoints.sm)} {
    display: block;
  }
`;

export default Header;
