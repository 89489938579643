/**
 * App에서 사용되는 URL을 정의하는 Class
 *
 * @example
 * new DeepLink('https://nepto.com');
 */
export class Link {
  private _url: string = '';
  private _params: URLSearchParams | null = null;

  get params() {
    return this._params;
  }

  get url() {
    if (this._params) return `${this._url}?${Link.paramsToString(this._params)}`;
    return this._url;
  }

  /**
   * URLSearchParams를 문자열로 변환 함수
   */
  static paramsToString(params: URLSearchParams) {
    return params.toString();
  }

  /**
   * 문자열을 URLSearchParams으로 변환 함수
   */
  static stringToParams = (search: string) => {
    return new URLSearchParams(search);
  };

  /**
   * URLSearchParams를 Dictionary 형태의 객체로 변환 함수
   */
  static paramsToDict = (params: URLSearchParams) => {
    return Object.fromEntries(params);
  };

  constructor(url: string, options?: Options) {
    if (!url) return;

    const [_url, _search] = url.split('?');
    this._url = _url;

    const _params = Link.stringToParams(_search);
    if (options?.params) {
      for (const key in options.params) {
        const value = options.params[key] ?? '';
        if (value !== '') _params.append(key, options.params[key]);
      }
    }
    this._params = _params;
  }
}

export interface Options {
  params: object;
}
