import Section1 from 'Components/Home/section1';
import Section3 from 'Components/Home/Section3';
import Section4 from 'Components/Home/Section4';
import Section5 from 'Components/Home/Section5';
import Section9 from 'Components/Home/Section9';
// import Section6 from 'Components/Home/Section6';
import Section7 from 'Components/Home/Section7';
import Section8 from 'Components/Home/Section8';
import Section10 from 'Components/Home/Section10';
import Section11 from 'Components/Home/Section11';
import EmailCollect from 'Components/Home/EmailCollect';
import OgBanner from 'Components/Home/OgBanner';

const Home = () => {
  return (
    <>
      <Section1 />
      <OgBanner />
      <Section3 />
      <Section4 />
      <Section5 />
      {/* <Section6 /> */}
      <Section7 />
      <Section8 />
      <Section9 />
      <Section10 />
      <EmailCollect />
      <Section11 />
    </>
  );
};
export default Home;
