import { useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
// components
import Container from 'Components/Atoms/Container';
import Font from 'Components/Atoms/Font';
import Layout from 'Components/Common/Layout';
// lib
import ImageAsset from 'Lib/Model/Asset/image';
// constants
import { nrns } from 'Constants/nrn';
// style
import { media, mediaQuery } from 'Styles/neptoTheme';
// util
import { checkMobile } from 'Util/checkMobile';

const IMAGES = [
  {
    id: 0,
    className: 'mask-image',
    src: ImageAsset.convertNrnToUri(nrns.DIRECT_MESSAGE_IMAGE_ORIGINAL, {
      format: 'webp',
      width: 1588,
    }),

    alt: 'dm-original',
  },
  {
    id: 1,
    className: '',
    src: ImageAsset.convertNrnToUri(nrns.DIRECT_MESSAGE_IMAGE, {
      format: 'webp',
      width: 1588,
    }),

    alt: 'dm',
  },
];

const MOBILE_IMAGES = [
  {
    id: 0,
    className: 'original-image',
    src: ImageAsset.convertNrnToUri(nrns.DIRECT_MESSAGE_IMAGE_MOBILE_ORIGINAL, {
      format: 'webp',
      width: 1588,
    }),

    alt: 'dm-original',
  },
  {
    id: 1,
    className: '',
    src: ImageAsset.convertNrnToUri(nrns.DIRECT_MESSAGE_IMAGE_MOBILE, {
      format: 'webp',
      width: 1588,
    }),

    alt: 'dm',
  },
];

const Section7 = () => {
  const layoutRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const maskRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const isMobile = checkMobile();

  useEffect(() => {
    let animationFrameId;
    const handleMouseMove = e => {
      const { clientX, clientY } = e;
      if (containerRef.current && maskRef.current) {
        const { y: maskY, x: maskX } = maskRef.current.getBoundingClientRect();
        const x = clientX - maskX;
        const y = clientY - maskY;

        containerRef.current.style.setProperty('--x', `${x}px`);
        containerRef.current.style.setProperty('--y', `${y}px`);
      }
    };

    const handleScroll = () => {
      if (animationFrameId) {
        // 이미 예약된 애니메이션 프레임이 있다면 취소.
        cancelAnimationFrame(animationFrameId);
      }
      animationFrameId = requestAnimationFrame(() => {
        if (!containerRef.current || !layoutRef.current || !imageRef.current) return;
        const wrapperOffsetTop = layoutRef.current.offsetTop;
        const wrapperOffsetHeight = layoutRef.current.offsetHeight;
        const containerHeight = containerRef.current.offsetHeight;
        const scrollY = window.scrollY;
        const windowHeight = window.innerHeight;
        const offset = 88 + 160 + 58 + 64 + containerHeight;
        const ratio =
          (scrollY + windowHeight - (wrapperOffsetTop + offset)) / (wrapperOffsetHeight - offset);

        const getValueFromRate = (start: number, end: number, rate: number) => {
          if (rate < 0) {
            return start;
          } else if (rate > 1) {
            return end;
          } else {
            return start + (end - start) * rate;
          }
        };

        if (ratio >= -0.2 && ratio <= 1.2) {
          imageRef.current.style.width = `${Math.min(
            Math.floor(getValueFromRate(0, 100, ratio)),
            100,
          )}%`;
          imageRef.current.style.willChange = 'width';
        } else {
          imageRef.current.style.willChange = 'auto';
        }
      });
    };

    if (isMobile) {
      window.addEventListener('scroll', handleScroll);
    } else {
      window.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
      if (isMobile) {
        window.removeEventListener('scroll', handleScroll);
      } else {
        window.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, []);

  return (
    <Layout
      ref={layoutRef}
      css={css`
        height: max(1000px, 150vh);

        ${media.md} {
          height: auto;
        }
      `}
    >
      <Container
        ref={containerRef}
        containerCss={css`
          position: sticky;
          top: calc(50% - 260px);
          ${media.sm} {
            top: 100px;
          }
          ${media.md} {
            top: inherit;
            position: inherit;
          }
          padding: 0 24px;
          max-width: inherit !important;
        `}
      >
        <div
          css={css`
            margin: 0 auto;
          `}
        >
          <Font
            type="Heading2"
            as="h2"
            style={css`
              margin-bottom: 24px;
              text-align: center;
              background: linear-gradient(90deg, #ff9be9 0%, #f33 100%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            `}
          >
            Direct Message
          </Font>

          <Font
            type="Heading1"
            as="h3"
            style={css`
              text-align: center;
            `}
          >
            Nepto’s direct messages <br /> are private and secure
          </Font>

          <Font
            as="h4"
            type="Display4"
            style={css`
              margin: 16px 0;
              text-align: center;
            `}
          >
            End-to-end encrypted messaging{' '}
          </Font>
        </div>

        {/* TODO: 넵토 앱 런칭 시 주석 롤백 */}
        {/*<LearnMoreButton>*/}
        {/*  <button>*/}
        {/*    <Font type="Strong2" textColor="primaryFont">*/}
        {/*      LEARN MORE*/}
        {/*    </Font>*/}
        {/*  </button>*/}
        {/*</LearnMoreButton>*/}

        <ContentsContainer className="desktop">
          {IMAGES.map(image => {
            return (
              <ImageWrap
                ref={image.className !== '' ? maskRef : null}
                className={image.className}
                key={image.id}
              >
                <img src={image.src} alt={image.alt} />
              </ImageWrap>
            );
          })}
        </ContentsContainer>

        <ContentsContainer className="mobile">
          {MOBILE_IMAGES.map(image => {
            return (
              <MobileImageWrap
                ref={image.className === 'original-image' ? imageRef : null}
                className={image.className}
                key={image.id}
              >
                <img src={image.src} alt={image.alt} />
              </MobileImageWrap>
            );
          })}
        </ContentsContainer>
      </Container>
    </Layout>
  );
};

const LearnMoreButton = styled.div`
  text-align: center;
  button {
    padding: 16px 0;
    border-bottom: 2px solid ${({ theme }) => theme.colorTheme.primaryFont};
    scale: 1;
    opacity: 1;
    transition: all 0.2s;

    &:hover {
      opacity: 0.7;
    }

    &:active {
      scale: 0.9;
    }
  }
`;

const ContentsContainer = styled.div`
  width: fit-content;
  margin: 0 auto;
  margin-top: 64px;
  position: relative;

  &.desktop {
    display: none;
    ${({ theme }) => mediaQuery(theme.breakPoints.md)} {
      display: block;
    }
  }

  &.mobile {
    display: block;
    ${({ theme }) => mediaQuery(theme.breakPoints.md)} {
      display: none;
    }
  }
`;

const ImageWrap = styled.div`
  width: 100%;
  max-width: 794px;
  margin: 0 auto;
  text-align: center;
  border-radius: 68px;
  & img {
    width: 100%;
    max-width: 794px;
  }

  &:hover {
    cursor: none;
  }

  &.mask-image {
    --mask: radial-gradient(circle at var(--x, 0) var(--y, 0), black 150px, transparent 0);
    position: absolute;
    z-index: 0;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.05);
    -webkit-mask-image: var(--mask);
    mask-image: var(--mask);

    &::before {
      content: '';
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.12);
    }
  }
`;

const MobileImageWrap = styled.div`
  width: 100%;
  max-width: 794px;
  height: 100%;
  margin: 0 auto;
  transition: all 100ms;

  &.original-image {
    width: 0;
    will-change: auto;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    & img {
      min-width: min(794px, calc(100vw - 48px));
    }
  }

  & img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export default Section7;
