// lib
import { Link } from 'Lib/Model/Link';
// type
import type { AssetArgs, NrnOptions } from 'Types/nrn';

/**
 * Asset
 * @class
 * @example
 * new Asset({ uri: someUri, nrnCategory: 'user-profile', nrnFileType: 'image' }).getNrn();
 * new Asset({ nrn: someNrn }).uri;
 */
class Asset {
  private _uri: string | null = null;
  private _nrn: NRN | null = null;
  private _nrnOptions: NrnOptions | null;

  get uri(): string | null {
    return this._uri ?? (this._nrn ? this.getUri() : null);
  }

  get nrn(): NRN | null {
    return this._nrn;
  }

  private getUri(): string | null {
    if (!this._nrn) return null;
    const argsLength = this._nrn.split('/').length;
    let type: 'internal' | 'general' | null = null;

    let prefix, category, format, fileName;
    let link: Link | null = null;

    switch (argsLength) {
      case 3:
        type = 'internal';
        [prefix, format, fileName] = this._nrn.split('/');
        break;
      case 4:
        type = 'general';
        [prefix, category, format, fileName] = this._nrn.split('/');
        break;
    }
    const defaultUrl = `${process.env.REACT_APP_CDN_SERVER_FILE}/v1/file`;
    const defaultParams = {
      ...(this._nrnOptions?.width && { width: Math.floor(this._nrnOptions.width) }),
      ...(this._nrnOptions?.height && { height: Math.floor(this._nrnOptions.height) }),
    };

    switch (type) {
      case 'internal':
        link = new Link(defaultUrl, {
          params: {
            uri: `${prefix}/${this._nrnOptions?.format ?? format}/${fileName}`,
            ...defaultParams,
          },
        });
        break;
      case 'general':
        link = new Link(defaultUrl, {
          params: Object.assign({
            uri: `${prefix}/${category}/${this._nrnOptions?.format ?? format}/${fileName}`,
            ...defaultParams,
          }),
        });
        break;

      default:
        link = new Link(defaultUrl, {
          params: {
            uri: this._nrn,
            ...defaultParams,
          },
        });
    }
    return link.url;
  }

  constructor({ nrn, nrnOptions }: AssetArgs) {
    if (!nrn) throw Error('must need nrn');

    this._nrn = nrn ?? null;
    this._nrnOptions = nrnOptions ?? null;
  }
}

export default Asset;
