import { useCallback, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
// components
import Container from 'Components/Atoms/Container';
import Font from 'Components/Atoms/Font';
import Layout from 'Components/Common/Layout';
// lib
import ImageAsset from 'Lib/Model/Asset/image';
// constant
import { nrns } from 'Constants/nrn';
// style
import { media } from 'Styles/neptoTheme';

const IMG = [
  {
    id: 0,
    img: nrns.CONNECT_WALLET_OWNERS,
    text: 'Contact wallet owners to offer trades on assets they own',
  },
  {
    id: 1,
    img: nrns.SEND_ANNOUNCEMENT_MESSAGE,
    text: 'Send announcement messages to holders as a project manager',
  },
  {
    id: 2,
    img: nrns.ADVERTISE_TO_TARGETED,
    text: 'Advertise to a targeted audience to showcase a new project',
  },
];

const Section5 = () => {
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0);
  const layoutRef = useRef<HTMLDivElement>(null);

  const itemStyle = useCallback(
    (index: number) => css`
      transform: scale(1) translateX(0);
      gap: 24px;
      transition: all 0.5s ease;
      display: flex !important;
      flex-direction: column !important;
      max-width: 300px;
      padding: 24px;
      :not(.active) {
        opacity: 0;
      }
      ${media.sm} {
        max-width: 553px;
      }
      ${media.md} {
        max-width: 593px;
        .active {
          transform: scale(1) translateX(${100 - index * 100}%) !important;
        }

        .item {
          transform: scale(0.8) translateX(${100 - index * 100}%) !important;
        }
        :not(.active) {
          opacity: 0.2;
        }
      }
    `,
    [activeImageIndex],
  );

  useEffect(() => {
    const handleScroll = () => {
      if (layoutRef?.current) {
        const top = layoutRef.current.offsetTop;
        const height = layoutRef.current.offsetHeight;
        const scrollY = window.scrollY;
        const windowHeight = window.innerHeight;
        const stickyStartPosition = windowHeight - height / 2;
        const offset = stickyStartPosition + height / 2;
        const ratio = (scrollY + windowHeight - (top + offset)) / (height - offset);

        if (ratio < 0 || ratio > 1) return;
        setActiveImageIndex(Math.floor(ratio * 3));
      }
    };
    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Layout ref={layoutRef}>
      <Container
        containerCss={css`
          position: relative;
          max-width: 100%;
          text-align: center;
          height: 300vh;
          display: flex;
          flex-direction: column;
          padding: 106px 0 212px 0;
          min-height: 720px;
          z-index: 1;
        `}
        id="section5"
      >
        <div
          css={css`
            position: sticky;
            top: 150px;
            ${media.md} {
              top: calc(${window.innerHeight / 2}px - 300px);
            }
            ${media.lg} {
              top: calc(${window.innerHeight / 2}px - 272px);
            }
          `}
        >
          <Font
            type="Strong1"
            textColor="primaryFont"
            style={css`
              display: block;
              margin-bottom: 24px;
            `}
          >
            Use Token Message To
          </Font>
          <ContentsWrapper activeImageIndex={activeImageIndex}>
            {IMG.map(value => (
              <div
                key={value.id}
                className={
                  activeImageIndex === value.id
                    ? 'item active'
                    : Math.abs(activeImageIndex - value.id) !== 1
                      ? 'hidden item'
                      : 'item'
                }
                css={() => itemStyle(activeImageIndex)}
              >
                <Img
                  src={ImageAsset.convertNrnToUri(value.img, {
                    format: 'webp',
                    width: 1090,
                  })}
                  alt={`nepto-logo${value.id}}`}
                />

                <Font type="Heading3">{value.text}</Font>
              </div>
            ))}
          </ContentsWrapper>
        </div>
      </Container>
    </Layout>
  );
};

const Img = styled.img`
  max-width: 345px;
  ${media.sm} {
    max-width: 505px;
  }
  ${media.md} {
    max-width: 545px;
  }
`;

const ContentsWrapper = styled.div<{ activeImageIndex: number }>`
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;

  .item {
    opacity: 0.2;
    justify-content: center;
    align-items: center;
    display: flex;
    transform: ${props =>
      `scale(0.8) translateX(${100 - props.activeImageIndex * 100}%) !important`};
    :not(.active) {
      position: relative;
      left: ${props =>
        props.activeImageIndex === 2 ? '-70px' : props.activeImageIndex === 1 ? '0px' : '70px'};
    }
  }

  .hidden {
    opacity: 0 !important;
  }

  .active {
    opacity: 1 !important;
    transform: ${props => `scale(1) translateX(${100 - props.activeImageIndex * 100}%) !important`};
  }
`;

export default Section5;
