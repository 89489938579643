import { useEffect, useRef, useState } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
// components
import Layout from 'Components/Common/Layout';
import Font from 'Components/Atoms/Font';
import Container from 'Components/Atoms/Container';
// lib
import ImageAsset from 'Lib/Model/Asset/image';
// constant
import { nrns } from 'Constants/nrn';
// style
import { media, mediaQuery } from 'Styles/neptoTheme';
// util
import { checkMobile } from 'Util/checkMobile';
import { reduceContractAdress } from 'Util/wallet';

const CENTER_IMAGES = [
  {
    positionY: 56,
    images: [
      {
        name: 'Moonbirds',
        src: ImageAsset.convertNrnToUri(nrns.NFT_MOONBIRDS, {
          format: 'original',
          width: 376,
        }),
        alt: 'Moonbirds',
        hoverFilter: css`
          filter: drop-shadow(0px 16px 32px rgba(74, 75, 116, 0.64));
        `,
        tooltip: '0x23581767a106ae21c074b2276D25e5C3e136a68b',
        animationDelay: 0.2,
        position: {
          left: '0',
          bottom: (-73 * 1.2).toString(),
          origin: 'left center',
        },
      },
      {
        name: 'Lido',
        src: ImageAsset.convertNrnToUri(nrns.NFT_RIDODAO, {
          format: 'original',
          width: 376,
        }),
        alt: 'Lido',
        hoverFilter: css`
          filter: drop-shadow(0px 16px 32px rgba(247, 170, 140, 0.64));
        `,
        tooltip: '0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32',
        animationDelay: 0.3,
        position: {
          left: '0',
          bottom: (-73 * 1.2).toString(),
          origin: 'left center',
        },
      },
    ],
  },
  {
    positionY: 83,
    images: [
      {
        name: 'Azuki',
        src: ImageAsset.convertNrnToUri(nrns.NFT_AZUKI, {
          format: 'original',
          width: 376,
        }),
        alt: 'Azuki',
        hoverFilter: css`
          filter: drop-shadow(0px 16px 32px rgba(188, 55, 72, 0.72));
        `,
        tooltip: '0xED5AF388653567Af2F388E6224dC7C4b3241C544',
        animationDelay: 0.4,
        position: {
          left: 'center',
          bottom: (-73 * 1.2).toString(),
          origin: 'center center',
        },
      },
      {
        name: 'Worldcoin',
        src: ImageAsset.convertNrnToUri(nrns.NFT_WORLDCOIN, {
          format: 'original',
          width: 376,
        }),
        alt: 'Worldcoin',
        hoverFilter: css`
          filter: drop-shadow(0px 16px 32px rgba(131, 131, 131, 0.64));
        `,
        tooltip: '0x163f8C2467924be0ae7B5347228CABF260318753',
        animationDelay: 0.5,
        position: {
          left: 'center',
          bottom: (-73 * 1.2).toString(),
          origin: 'center center',
        },
      },
    ],
  },
  {
    positionY: 18,
    images: [
      {
        name: 'Uniswap',
        src: ImageAsset.convertNrnToUri(nrns.NFT_UNISWAP, {
          format: 'original',
          width: 376,
        }),
        alt: 'Uniswap',
        hoverFilter: css`
          filter: drop-shadow(0px 16px 32px rgba(255, 240, 250, 0.64));
        `,
        tooltip: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
        animationDelay: 0.6,
        position: {
          right: '0',
          bottom: (-73 * 1.2).toString(),
          origin: 'right center',
        },
      },
      {
        name: 'Pudgy Penguins',
        src: ImageAsset.convertNrnToUri(nrns.NFT_PUDGY_PENGUINS, {
          format: 'original',
          width: 376,
        }),
        alt: 'Pudgy Penguins',
        hoverFilter: css`
          filter: drop-shadow(0px 16px 32px rgba(129, 171, 255, 0.64));
        `,
        tooltip: '0xBd3531dA5CF5857e7CfAA92426877b022e612cf8',
        animationDelay: 0.7,
        position: {
          right: '0',
          bottom: (-73 * 1.2).toString(),
          origin: 'right center',
        },
      },
    ],
  },
];

const ABSOLUTE_IMAGES = [
  {
    name: 'Degods',
    src: ImageAsset.convertNrnToUri(nrns.NFT_DEGODS, {
      format: 'original',
      width: 196,
    }),
    alt: '',
    top: 0,
    left: 0,
    hoverFilter: css`
      filter: drop-shadow(0px 16px 32px rgba(93, 103, 138, 0.64));
    `,
    css: css`
      width: 98px;
    `,
    tooltip: '0x8821BeE2ba0dF28761AffF119D66390D594CD280',
    animationDelay: 0.1,
  },
  {
    name: 'Shibainu',
    src: ImageAsset.convertNrnToUri(nrns.NFT_SHIBAINU, {
      format: 'original',
      width: 196,
    }),
    alt: '',
    bottom: 16,
    left: 72,
    hoverFilter: css`
      filter: drop-shadow(0px 16px 32px rgba(241, 88, 1, 0.64));
    `,
    css: css`
      width: 139px;
    `,
    tooltip: '0xfD1450a131599ff34f3Be1775D8c8Bf79E353D8c',
    animationDelay: 0.2,
  },
  {
    name: 'Clonex',
    src: ImageAsset.convertNrnToUri(nrns.NFT_CLONEX, {
      format: 'original',
      width: 196,
    }),
    alt: '',
    top: 32,
    right: 0,
    hoverFilter: css`
      filter: drop-shadow(0px 16px 32px rgba(134, 21, 103, 0.64));
    `,
    css: css`
      width: 118px;
    `,
    tooltip: '0x49cF6f5d44E70224e2E23fDcdd2C053F30aDA28B',
    animationDelay: 0.3,
  },
];

const Section9 = () => {
  const timerRef = useRef<NodeJS.Timeout[]>([]);
  const tokenRef = useRef<HTMLDivElement>(null);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const layoutRef = useRef<HTMLDivElement>(null);
  const [isOverLap, setIsOverLap] = useState<boolean>(false);
  const [hoveredImage, setHoveredImage] = useState<{
    name: string;
    tooltip: string;
    alt: string;
  }>({ name: '', tooltip: '', alt: '' });
  const [isHoveredImage, setIsHoveredImage] = useState<boolean>(false);
  const isMobile = checkMobile();

  // 랜덤 문자열 생성 함수
  const generateRandomString = (length: number) => {
    // 가능한 문자열의 집합
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return [...Array(length)]
      .map(() => characters.charAt(Math.floor(Math.random() * characters.length)))
      .join('');
  };

  const handleMouseLeave = () => {
    setIsHoveredImage(false);
    timerRef.current.forEach(timer => clearTimeout(timer));
    timerRef.current = [];

    // 커서가 이미지에서 벗어나면서 이름이 바로 사라지는 것을 방지하기 위해 timeout 적용
    timer.current = setTimeout(() => {
      setHoveredImage({ name: '', tooltip: '', alt: '' });
    }, 5000);
  };

  useEffect(() => {
    const clearTimers = () => {
      timerRef.current.forEach(timer => clearTimeout(timer));
      timerRef.current = [];
    };
    return clearTimers;
  }, []);

  const handleMouseOver = (value: typeof hoveredImage) => {
    const initialName = generateRandomString(value.name.length);
    const initialTooltip = generateRandomString(reduceContractAdress(value.tooltip).length);
    setHoveredImage({
      name: initialName,
      tooltip: initialTooltip,
      alt: value.alt,
    });
    setIsHoveredImage(true);

    const maxIndex = Math.max(value.name.length, reduceContractAdress(value.tooltip).length);
    for (let index = 0; index < maxIndex; index++) {
      const timer = setTimeout(() => {
        setHoveredImage(prev => {
          const newName =
            index < value.name.length
              ? prev.name.substring(0, index) +
                value.name.charAt(index) +
                prev.name.substring(index + 1)
              : prev.name;
          const newTooltip =
            index < reduceContractAdress(value.tooltip).length
              ? prev.tooltip.substring(0, index) +
                reduceContractAdress(value.tooltip).charAt(index) +
                prev.tooltip.substring(index + 1)
              : prev.tooltip;
          return {
            name: newName,
            tooltip: newTooltip,
            alt: value.alt,
          };
        });
      }, index * 50);
      timerRef.current.push(timer);
    }
  };

  useEffect(() => {
    // 커서가 이동할때 타이머가 있다면 타이머를 지우고 현재 hover 된 이미지의 tooltip 내용으로 교체
    if (hoveredImage && timer.current) {
      clearTimeout(timer.current);
    }
  }, [hoveredImage]);

  useEffect(() => {
    const handleMouseControl = e => {
      const { clientX, clientY } = e;
      if (containerRef?.current) {
        // wrapper container가 화면에 나타나면 값을 세팅
        containerRef.current.style.setProperty('--x', `${clientX + 20}px`);
        containerRef.current.style.setProperty('--y', `${clientY + 20}px`);
      }
    };
    window.addEventListener('mousemove', handleMouseControl);

    return () => {
      window.removeEventListener('mousemove', handleMouseControl);
    };
  }, []);

  return (
    <div>
      <Layout ref={layoutRef}>
        <Container
          ref={containerRef}
          containerCss={css`
            padding: 180px 0 0;
            height: auto;
          `}
        >
          <div
            css={css`
              width: 100%;
              height: auto;
            `}
          >
            <NftWrapper ref={tokenRef} isOverLap={isOverLap}>
              {CENTER_IMAGES.map((v, i) => (
                <NftColumn $y={v.positionY} key={i}>
                  {v.images?.map((image, index) => (
                    <div
                      key={index}
                      css={css`
                        position: relative;
                      `}
                    >
                      <Image
                        initial={{ opacity: 0, y: 100 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        src={image.src}
                        alt={image.alt}
                        $hoverStyle={image.hoverFilter}
                        $isHovered={
                          hoveredImage && isHoveredImage ? hoveredImage.name === image.name : null
                        }
                        $animationDelay={image.animationDelay}
                        onMouseEnter={() => handleMouseOver(image)}
                        onMouseLeave={() => handleMouseLeave()}
                        css={css`
                          transform-origin: ${image.position.origin};
                          width: 100px;
                          ${media.sm} {
                            width: 140px;
                          }
                          ${media.md} {
                            width: 160px;
                            transform-origin: initial;
                          }
                          ${media.lg} {
                            width: 188px;
                          }
                        `}
                      />
                      {isMobile && (
                        <Tooltip
                          className={
                            isHoveredImage && hoveredImage.alt === image.alt ? 'visible' : 'hidden'
                          }
                          top={image.position.top}
                          left={image.position.left}
                          bottom={image.position?.bottom}
                          right={image.position?.right}
                        >
                          <Font
                            type="Caption1"
                            style={css`
                              white-space: nowrap;
                            `}
                          >
                            {hoveredImage?.name}
                          </Font>
                          <Font type="Caption2">{hoveredImage?.tooltip}</Font>
                        </Tooltip>
                      )}
                    </div>
                  ))}
                </NftColumn>
              ))}
              {ABSOLUTE_IMAGES.map((image, i) => (
                <AbsoluteNft
                  key={i}
                  $top={image.top}
                  $left={image.left}
                  $right={image.right}
                  $bottom={image.bottom}
                >
                  <Image
                    initial={{ opacity: 0, y: 100 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    src={image.src}
                    alt={image.alt}
                    $hoverStyle={image.hoverFilter}
                    $isHovered={
                      hoveredImage && isHoveredImage ? hoveredImage.name === image.name : null
                    }
                    onMouseEnter={() => handleMouseOver(image)}
                    onMouseLeave={() => handleMouseLeave()}
                    css={css`
                      ${image.css}
                    `}
                  />
                </AbsoluteNft>
              ))}
            </NftWrapper>
          </div>

          {!isMobile && (
            <Tooltip className={isHoveredImage ? 'visible' : 'hidden'}>
              <Font type="Caption1">{hoveredImage?.name}</Font>
              <Font type="Caption2">{hoveredImage?.tooltip}</Font>
            </Tooltip>
          )}
        </Container>
      </Layout>
    </div>
  );
};

const NftWrapper = styled.div<{ isOverLap: boolean }>`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  height: 320px;
  gap: 12px;
  opacity: ${({ isOverLap }) => (isOverLap ? 0 : 1)};
  transition: opacity 0.3s ease-in-out;
  ${media.sm} {
    height: 490px;
  }
`;

const NftColumn = styled.div<{ $y?: number }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  transform: translateY(${({ $y }) => $y || 0}px);
  z-index: 0;
  &:hover {
    z-index: 2;
  }
`;

const AbsoluteNft = styled.div<{
  $left?: number;
  $top?: number;
  $bottom?: number;
  $right?: number;
}>`
  display: none;
  ${({ theme }) => mediaQuery(theme.breakPoints.md)} {
    display: block;
  }
  position: absolute;
  left: ${({ $left }) => `${$left}px` || 'inherit'};
  top: ${({ $top }) => `${$top}px` || 'inherit'};
  bottom: ${({ $bottom }) => `${$bottom}px` || 'inherit'};
  right: ${({ $right }) => `${$right}px` || 'inherit'};
`;

const Image = styled(motion.img)<{
  $hoverStyle: SerializedStyles;
  $isHovered: boolean | null;
  $animationDelay?: number;
}>`
  display: block;
  position: relative;
  cursor: pointer;
  transform: scale(1);
  transition-property: transform, filter;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 0, 0.17, 1);

  z-index: 0;
  filter: ${({ $isHovered }) =>
    $isHovered === null ? 'brightness(1)' : $isHovered ? 'brightness(1)' : 'brightness(0.3)'};
  &:hover {
    z-index: 3;
    transform: scale(1.2) !important;
    ${({ $hoverStyle }) => $hoverStyle}
  }
`;

const Tooltip = styled.div<{
  top?: number | string;
  left?: number | string;
  bottom?: number | string;
  right?: number | string;
}>`
  position: absolute;
  top: ${({ top }) => (top ? `${top}px` : 'inherit')};
  // left가 center일 경우에는 left를 50%로, left가 center가 아니면 px단위를 사용
  left: ${({ left }) => (left === 'center' ? '50%' : left ? `${left}px` : 'inherit')};
  bottom: ${({ bottom }) => (bottom ? `${bottom}px` : 'inherit')};
  right: ${({ right }) => (right ? `${right}px` : 'inherit')};
  transform: ${({ left }) => (left === 'center' ? 'translateX(-50%)' : 'inherit')};
  margin-top: 4px;
  gap: 4px;
  z-index: 4;
  padding: 12px 20px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colorTheme.reverse12};
  backdrop-filter: blur(12px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
  text-align: center;
  &.visible {
    animation: fadeIn 0.2s forwards;
  }

  &.hidden {
    animation: fadeOut 0.2s forwards;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }

  @keyframes fadeOut {
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${media.md} {
    position: fixed;
    text-align: left;
    top: var(--y);
    left: var(--x);
  }
`;

export default Section9;
