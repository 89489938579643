import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import TagManager from 'react-gtm-module';
import AOS from 'aos';
// components
import Header from 'Components/Common/Header';
import Footer from 'Components/Common/Footer';
// pages
import Home from 'pages';
// styles
import { neptoTheme } from 'Styles/neptoTheme';
import GlobalStyle from 'Styles/GlobalStyle';
import 'aos/dist/aos.css';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      disable: false,
      mirror: true,
      offset: 120,
    });

    process.env.REACT_APP_GTM_ID && TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
  }, []);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageView',
        page: {
          url: location.pathname,
          title: window.document.title,
        },
      },
    });
  }, [location]);

  return (
    <ThemeProvider theme={neptoTheme}>
      <GlobalStyle />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
    </ThemeProvider>
  );
};

export default App;
