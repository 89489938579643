import { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
// lib
import { ColorType } from 'Styles/neptoTheme';

const Icon: FC<Props> = ({
  type = 'line',
  className = '',
  size = 24,
  color = 'shade900',
  children,
  ...rest
}) => {
  const iconClassName = `icon ${type} ${className}`;

  return (
    <I className={iconClassName} color={color} size={size} {...rest}>
      {children}
    </I>
  );
};

const I = styled.i<{ color: string; size: number }>`
  font-family: 'Nepto-Icon-Line' !important;
  color: ${({ theme, color }) => theme.colorTheme[color]};
  font-style: normal;
  display: inline-flex;
  font-variant: normal;
  font-weight: 400; // Default
  font-size: ${({ size }) => (size ? size + 'px' : '24px')}; // Default
  width: ${({ size }) => (size ? size + 'px' : '24px')};
  height: ${({ size }) => (size ? size + 'px' : '24px')};
  text-transform: none;
  text-rendering: optimizeLegibility;
  line-height: normal;
  letter-spacing: 0;
  font-feature-settings: 'liga';
  font-variant-ligatures: discretionary-ligatures;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  // Disable user select
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &.solid {
    font-family: 'Nepto-Icon-Solid' !important;
  }
  // size
  &.medium {
    font-size: 32px;
    height: 32px;
    width: 32px;
  }

  &.large {
    font-size: 40px;
    height: 40px;
    width: 40px;
  }

  &.More-Horizontal {
    &:before {
      content: '\f2f2';
    }
  }

  &.Open-In-New {
    &:before {
      content: '\f2fb';
    }
  }
  &.Chevron-Right-cr-fr {
    &:before {
      content: '\f1b7';
    }
    rotate: calc(180deg);
  }
  &.Chevron-down-cr-fr {
    &:before {
      content: '\f1b7';
    }
    rotate: calc(270deg);
  }
  &.File-Text-01 {
    letter-spacing: -0.1em;
  }
`;

interface Props {
  type?: 'solid' | 'line';
  className?: string;
  color?: ColorType;
  size?: number;
  margin?: string;
  children?: ReactNode;
}

export default Icon;
