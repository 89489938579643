import { useState } from 'react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
// components
import Layout from 'Components/Common/Layout';
import Container from 'Components/Atoms/Container';
import Font from 'Components/Atoms/Font';
import StoreButton from 'Components/Common/StoreButton';
import QrCode from 'Components/Common/QrCode';
import AosComponent from 'Components/Atoms/AosComponent';
// constants
import { nrns } from 'Constants/nrn';
// lib
import ImageAsset from 'Lib/Model/Asset/image';
// style
import { media } from 'Styles/neptoTheme';
// util
import { checkMobile, checkMobileType } from 'Util/checkMobile';
// icons
import { ReactComponent as TelegramIcon } from 'assets/svgIcons/ic_telegram.svg';
import { Link } from 'react-router-dom';

const Section11 = () => {
  const { colorTheme } = useTheme();
  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <div
      css={css`
        background-image: url(${ImageAsset.convertNrnToUri(nrns.HEXAGON_BG_IMAGE)});
        background-size: contain;
        background-position: center;
        background-color: ${colorTheme.shade100};
      `}
    >
      <Layout>
        <Container
          containerCss={css`
            padding: 180px 24px;
            ${media.md} {
              padding: 180px 64px;
            }
            ${media.lg} {
              padding: 180px 0 !important;
            }
          `}
        >
          {/* TODO: 넵토 앱 런칭 시 주석 롤백 */}
          {/*<AosComponent>*/}
          {/*  <QrCode />*/}
          {/*</AosComponent>*/}
          <AosComponent
            aosCss={css`
              max-width: 789px;
              padding-top: 24px;
            `}
          >
            <Font type="Display2" textColor="shade900">
              Messenger made for <TitleBr /> Web3 users
            </Font>
          </AosComponent>
          <AosComponent
            aosCss={css`
              display: flex;
              flex-direction: column;
              gap: 24px;
              margin-top: 64px;
            `}
          >
            <Font type="Title1">
              Join Nepto community before the launch to receive latest information
            </Font>
            <DiscordButton to="https://t.me/nepto_official" target="_blank">
              <TelegramIcon />
              <Font type="Button">Join Telegram</Font>
            </DiscordButton>
          </AosComponent>

          {/* [TODO] 넵토런칭시 주석제거 */}
          {/*<AosComponent*/}
          {/*  aosCss={css`*/}
          {/*    padding-top: 44px;*/}
          {/*    gap: 24px;*/}
          {/*    display: flex;*/}

          {/*    ${media.lg} {*/}
          {/*      padding-top: 64px;*/}
          {/*    }*/}
          {/*  `}*/}
          {/*>*/}
          {/*  <StoreBtn*/}
          {/*    storeType="apple"*/}
          {/*    nrn={nrns.APP_STORE_SYMBOL_LIGHT}*/}
          {/*    text="App Store"*/}
          {/*    style={css`*/}
          {/*      padding: 16px 24px;*/}
          {/*      ${media.sm} {*/}
          {/*        padding: 24px 40px;*/}
          {/*      }*/}
          {/*    `}*/}
          {/*    className={checkMobile() && checkMobileType() === 'ios' ? 'visible' : ''}*/}
          {/*  />*/}
          {/*  <StoreBtn*/}
          {/*    storeType="google"*/}
          {/*    nrn={nrns.GOOGLE_PLAY_SYMBOL}*/}
          {/*    text="Google Play"*/}
          {/*    style={css`*/}
          {/*      padding: 16px 24px;*/}
          {/*      ${media.sm} {*/}
          {/*        padding: 24px 40px;*/}
          {/*      }*/}
          {/*    `}*/}
          {/*    className={checkMobile() && checkMobileType() === 'android' ? 'visible' : ''}*/}
          {/*  />*/}
          {/*</AosComponent>*/}
          {/* [TODO] 백서런칭시 주석제거 */}
          {/* <AosComponent
            aosCss={css`
              display: flex;
              padding-top: 16px;
              ${media.sm} {
                padding-top: 32px;
              }
            `}
          >
            <Button onMouseMove={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
              <Icon src={`/icons/file-text-${isHover ? 'white' : 'black'}.svg`} alt="file-text" />
              <Font type="Button" textColor="shade000">
                Nepto Whitepaper
              </Font>
            </Button>
          </AosComponent> */}
        </Container>
      </Layout>
    </div>
  );
};
const Icon = styled.img`
  width: 24px;
  height: 24px;
  ${media.sm} {
    width: 40px;
    height: 40px;
  }
`;

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 16px;
  padding: 16px 24px;
  border: 1px solid ${({ theme }) => theme.colorTheme.shade900};
  border-radius: 100px;
  color: ${({ theme }) => theme.colorTheme.shade000};
  transition: all 0.2s;
  background-color: ${({ theme }) => theme.colorTheme.shade900};

  &:hover {
    background-color: transparent;
    span {
      color: ${({ theme }) => theme.colorTheme.shade900};
    }
    i {
      color: ${({ theme }) => theme.colorTheme.shade700};
    }
  }
  ${media.xs} {
    i {
      width: 24px;
      height: 24px;
      font-size: 24px;
    }
  }
  ${media.sm} {
    padding: 24px 40px;
    i {
      width: 40px;
      height: 40px;
      font-size: 40px;
    }
  }
`;

const StoreBtn = styled(StoreButton)`
  display: flex;
  ${media.xs} {
    img {
      width: 24px;
      height: 24px;
      font-size: 24px;
    }
    :not(.visible) {
      display: none;
    }
  }
  ${media.sm} {
    :not(.visible) {
      display: flex;
    }
    display: flex !important;
    img {
      width: 40px;
      height: 40px;
      font-size: 40px;
    }
  }
  button {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
`;

const TitleBr = styled.br`
  display: none;
  ${media.sm} {
    display: block;
  }
`;

const DiscordButton = styled(Link)`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  padding: 24px 40px;
  border: 1px solid ${({ theme }) => theme.colorTheme.shade900};
  border-radius: 100px;
  transition: all 0.2s;
  &:hover {
    svg {
      path {
        transition: all 0.2s;
        fill: ${({ theme }) => theme.colorTheme.shade000};
      }
    }
    background-color: ${({ theme }) => theme.colorTheme.shade900};
    span {
      color: ${({ theme }) => theme.colorTheme.shade000};
    }
  }
`;

export default Section11;
