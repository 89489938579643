import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { css, useTheme } from '@emotion/react';
import { motion } from 'framer-motion';
import styled from '@emotion/styled';
import lottie from 'lottie-web';
// components
import Layout from 'Components/Common/Layout';
import Container from 'Components/Atoms/Container';
import Font from 'Components/Atoms/Font';
import AosComponent from 'Components/Atoms/AosComponent';
// constants
import { nrns } from 'Constants/nrn';
// lib
import ImageAsset from 'Lib/Model/Asset/image';
//styles
import { media } from 'Styles/neptoTheme';
// util
import { checkMobile } from 'Util/checkMobile';
// assets
import * as AnimationData from 'assets/lottie/desktop.json';

const Section10 = () => {
  const { breakPoints } = useTheme();
  const isMobile = checkMobile();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const stickyContainerRef = useRef<HTMLDivElement | null>(null);
  const cardRef = useRef<null[] | HTMLImageElement[]>([]);
  const cardContainerRef = useRef<HTMLDivElement | null>(null);
  const lottieRef = useRef<HTMLDivElement | null>(null);
  const [currentViewImageIndex, setCurrentViewImageIndex] = useState(0);
  const initialLastItemOffsetLeft = useRef<number>(0);
  const [isSectionInViewPort, setIsSectionInViewPort] = useState(false);
  const [loadedImages, setLoadedImages] = useState([false, false, false, false, false]);

  const MOBILE_CARDS = [
    {
      id: 1,
      src: '/images/section10/mobile/intro-wallet-card-1.png',
      initialScale: 1,
      animation: {
        startPosition: 0,
        endPosition: 0.3,
      },
    },
    {
      id: 2,
      src: '/images/section10/mobile/intro-wallet-card-2.png',
      initialScale: 0.9,
      animation: {
        startPosition: 0.3,
        endPosition: 0.6,
      },
    },
    {
      id: 3,
      src: '/images/section10/mobile/intro-wallet-card-3.png',
      initialScale: 0.9,
      animation: {
        startPosition: 0.6,
        endPosition: 1,
      },
    },
    {
      id: 4,
      src: '/images/section10/mobile/intro-wallet-card-4.png',
      initialScale: 0.9,
      animation: {
        startPosition: 0,
        endPosition: 0,
      },
    },
    {
      id: 5,
      src: '/images/section10/mobile/intro-wallet-card-5.png',
      initialScale: 0.9,
      animation: {
        startPosition: 0,
        endPosition: 0,
      },
    },
  ];
  const getValueFromRate = (start, end, rate) => {
    const _rate = Math.min(Math.abs(rate), 1);

    return start + (end - start) * _rate;
  };

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: lottieRef.current as any,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: AnimationData,
      rendererSettings: {
        // clearCanvas: true,
        progressiveLoad: true,
        hideOnTransparent: true,
      },
    });
    if (isSectionInViewPort) {
      anim.play();
    }

    return () => {
      anim.destroy();
    };
  }, [isSectionInViewPort]);

  useEffect(() => {
    if (loadedImages.some(loaded => !loaded)) {
      return;
    }

    /**
     * 카드 하나의 width의 반만큼 컨테이너의 left를 밀어주어야 첫번쨰 카드가 화면의 절반에서 시작함
     * --left-offset의 반을 card-container에서 left 값으로 사용
     */
    const setCardWidth = () => {
      if (!cardRef.current || !stickyContainerRef.current || !cardContainerRef.current) return;

      const cardContainerElem = document.getElementById('card-container');
      // 첫번쨰 카드가 화면 중앙에 위치해 있으려면 left offset을 얼마나 가져야 하는지를 계산
      cardContainerElem!.style.setProperty(
        '--left-offset',
        `${window.innerWidth / 2 - cardRef!.current[0]!.getBoundingClientRect().width / 2}px`,
      );
    };
    setCardWidth();
    // let cardContainerElem;
    // let _cardWidth;

    const lastIndex = 2; // 3번쨰 카드까지만 애니메이션 진행
    const lastItem = cardRef.current[lastIndex];

    // setTimeout(() => {
    const cardContainerElem = document.getElementById('card-container') as HTMLElement;
    const _cardWidth =
      parseInt(
        getComputedStyle(cardContainerElem).getPropertyValue('--left-offset').replace('px', ''),
      ) * 2; // 카드 한장의 width
    // }, 1000);

    if (initialLastItemOffsetLeft.current === 0 && lastItem) {
      // 스크롤 이벤트가 일어나면서 카드가 얼마나 밀려야 하는지를 셋팅
      initialLastItemOffsetLeft.current =
        lastItem!.getBoundingClientRect().x -
        (window.innerWidth - lastItem!.getBoundingClientRect().width) / 2;
    }

    const handleScroll = () => {
      if (!wrapperRef.current || !lottieRef.current || !containerRef.current) return;
      const containerOffsetTop = wrapperRef.current?.offsetTop;
      const containerOffsetHeight = wrapperRef.current?.offsetHeight;
      const containerHeight = containerRef.current?.offsetHeight;
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const { height } = lottieRef.current?.getBoundingClientRect() || { height: 0 };
      const stickyStartPosition = windowHeight / 2 - height / 2;
      const offset = stickyStartPosition + height + containerHeight + 106 + 500;

      const ratio =
        (scrollY + windowHeight - (containerOffsetTop + offset)) / (containerOffsetHeight - offset); // 현재 Wallet Section이 스크롤 된 비율을 0~1로 표현한 변수
      if (!cardRef.current || ratio > 1 || ratio < 0) return;

      MOBILE_CARDS.forEach((item, index) => {
        const { startPosition, endPosition } = item.animation;
        const _cardRef = cardRef.current[index];
        const getScale = () => {
          // 마지막 두개의 카드는 scale 애니메이션을 진행하지 않음
          if ([3, 4].includes(index)) {
            return '0.9';
          }
          if (ratio >= startPosition && ratio <= endPosition) {
            setCurrentViewImageIndex(index);
          }
          const calcScale = targetX => {
            const x = _cardWidth / 2;
            if (targetX <= x + 100 && targetX > x) {
              return 0.9 + (0.1 - (targetX - x) / 1000);
            } else if (targetX <= x) {
              return Math.max(1 - (x - targetX) / 1000, 0.9);
            } else {
              return 0.9;
            }
          };

          return calcScale(_cardRef!.getBoundingClientRect().x);
        };

        if (ratio <= 1 || ratio >= 0) {
          // 스크롤 비율이 0~1 사이일때만 애니메이션 진행
          _cardRef!.style.willChange = 'transform';
          // 스크롤 이벤트가 일어나는 동안 initialLastItemOffsetLeft 만큼만 카드가 왼쪽으로 이동
          _cardRef!.style.transform = `matrix(${getScale()}, 0, 0, ${getScale()}, -${getValueFromRate(
            0,
            initialLastItemOffsetLeft.current,
            ratio,
          )}, 0)`;
        } else {
          _cardRef!.style.willChange = 'auto';
          _cardRef!.style.transform = `matrix(${getScale()}, 0, 0, ${getScale()}, 0, 0)`;
        }
      });
    };

    handleScroll();
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [loadedImages]);

  return (
    <motion.div
      ref={wrapperRef}
      css={css`
        height: ${isMobile ? '2200px' : '100%'};
      `}
    >
      <Layout>
        <Container
          ref={containerRef}
          containerCss={css`
            padding: 80px 24px 0;
          `}
        >
          <div
            css={css`
              position: relative;
              z-index: 2;
              margin: 0;
              transform: translateY(0);
              ${media.md} {
                transform: translateY(200px);
              }
            `}
          >
            <AosComponent>
              <GradientFont>
                <Font type="Heading2">Wallet</Font>
              </GradientFont>
            </AosComponent>
            <div
              css={css`
                padding: 8px 0;
              `}
            />
            <div
              css={css`
                max-width: 795px;
              `}
            >
              <AosComponent aosDelay={200}>
                <Font type="Heading1" color="shade900">
                  Manage multiple wallets in one place, monitor your asset value at a glance
                </Font>
              </AosComponent>
            </div>
          </div>
        </Container>
      </Layout>
      <VideoFrameWrapper ref={stickyContainerRef}>
        {/* 데스크탑 */}
        <motion.div
          viewport={{ amount: 0.5, once: true }}
          onViewportEnter={() => setIsSectionInViewPort(true)}
          ref={lottieRef}
          className="video-image desktop"
          css={css`
            z-index: 2;
          `}
        />
        {/* 모바일 */}
        <MobileAnimationContainer className="video-image mobile">
          <div id="card-container" ref={cardContainerRef}>
            {MOBILE_CARDS.map((card, index) => {
              return (
                <Card
                  $initialScale={card.initialScale}
                  ref={ref => (cardRef.current[index] = ref)}
                  key={index}
                  src={`/images/section10/mobile/intro-wallet-card-${index + 1}.png`}
                  onLoad={() => {
                    setLoadedImages(prev => {
                      const _prev = [...prev];
                      _prev[index] = true;
                      return _prev;
                    });
                  }}
                  alt=""
                />
              );
            })}
          </div>
          <DotContainer>
            {Array.from({ length: 5 }).map((_, index) => {
              return (
                <Dot
                  className={currentViewImageIndex === index ? 'stretch' : 'shrink'}
                  key={index}
                />
              );
            })}
          </DotContainer>
        </MobileAnimationContainer>
        <img
          src={ImageAsset.convertNrnToUri(nrns.PINK_BG_IMAGE, {
            format: 'webp',
            width: 2888,
          })}
          css={css`
            z-index: 2;
            position: absolute;
            width: 100%;
            bottom: -50px;
            right: 0;
            @media screen and (min-width: ${breakPoints.lg}px) {
              right: -150px;
            }
            // 랩탑 디바이스 사이즈 대응용 media query
            @media screen and (max-device-height: 1000px) and (min-width: ${breakPoints.md}px) {
              right: -200px;
            }
            display: none;
            ${media.sm} {
              display: block;
            }
          `}
          alt="pink-bg-image"
        />
      </VideoFrameWrapper>
    </motion.div>
  );
};

const GradientFont = styled.div`
  background: linear-gradient(94deg, #9452ff 15.76%, #7165ff 98.11%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const VideoFrameWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-height: 100vh;
  position: sticky;

  ${media.md} {
    //padding-top: 50%;
    height: 100vh;
    min-height: inherit;
    max-height: 900px;
    position: relative;
  }
  top: 0;
  overflow: hidden;

  & .video-image {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%) !important;
    height: calc(100vh - 150px);
    max-height: 800px;
    width: 100%;
    object-fit: contain;
    margin: 0 auto;
    & canvas {
      width: 100%;
    }

    ${media.md} {
      top: 30%;
      transform: translate(-50%, -30%) !important;
      bottom: initial;
    }

    &.desktop {
      display: none;
      ${media.md} {
        display: block;
      }
    }

    &.mobile {
      display: block;
      ${media.md} {
        display: none;
      }
    }
  }
`;

const MobileAnimationContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url('/images/section10/mobile/intro-wallet-background.png');
  background-size: contain;
  background-position: top center;

  #card-container {
    position: absolute;
    transform-origin: center center;
    top: 40%;
    left: var(--left-offset, 116px);
    height: 100%;
    display: flex;
    gap: 12px;
  }
`;

const Card = styled.img<{ $initialScale: number }>`
  transform: scale(${({ $initialScale }) => $initialScale});
  transition: all 0.1s;
  display: inline-block;
  width: auto;
  height: 40%;
`;

const DotContainer = styled.div`
  position: absolute;
  top: 83%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 100%;
`;

const Dot = styled.span`
  will-change: width, filter;
  @keyframes stretch {
    from {
      width: 6px;
    }
    to {
      width: 18px;
    }
  }

  @keyframes shrink {
    from {
      width: 18px;
    }
    to {
      width: 6px;
    }
  }

  display: inline-block;
  width: 6px;

  &.stretch {
    animation: stretch 0.5s forwards;
    background-color: ${({ theme }) => theme.colorTheme.primaryFont};
  }
  &.shrink {
    animation: shrink 0.5s forwards;
    background-color: ${({ theme }) => theme.colorTheme.shade300};
  }

  transition: all 0.5s;
  height: 6px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colorTheme.primaryFont};
`;

export default Section10;
