import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
// components
import Font from 'Components/Atoms/Font';
import Container from 'Components/Atoms/Container';
import Icon from 'Components/Atoms/Icon';
import Layout from 'Components/Common/Layout';
// constants
import { nrns } from 'Constants/nrn';
// lib
import ImageAsset from 'Lib/Model/Asset/image';
// style
import { media } from 'Styles/neptoTheme';
import { motion } from 'framer-motion';

const CONTENTS = [
  {
    icon: 'Chats',
    name: 'SocialFi',
    text: 'Nepto provides monetization opportunity based on asset history of a wallet',
  },
  {
    icon: 'Lock',
    name: 'Spam Free',
    text: 'Token attached messages reduce spam and filters only relevant messages',
  },
  {
    icon: 'User',
    name: 'Privacy',
    text: 'There is no need to reveal wallet identity to be contacted by others on the blockchain',
  },
];

const Section4 = () => {
  const { colorTheme } = useTheme();

  return (
    <div
      css={css`
        position: relative;
        background-image: url(${ImageAsset.convertNrnToUri(nrns.BLUE_BG_IMAGE)});
        background-position: left bottom;
        padding-bottom: 60px;
        background-size: 200%;
        border-top: 1px solid ${colorTheme.shade200};
        border-bottom: 1px solid ${colorTheme.shade200};
        ${media.sm} {
          background-size: 130%;
        }
        ${media.md} {
          background-size: contain;
          background-position: 0% bottom !important;
        }
      `}
    >
      <Layout>
        <Container
          containerCss={css`
            position: relative;
            z-index: 3;
            display: flex;
            gap: 16px;
            flex-direction: column;
            padding: 0 24px;
            ${media.sm} {
              padding: 0 48px;
            }
            ${media.md} {
              flex-direction: row;
              padding: 0 24px;
            }
            ${media.lg} {
              padding: 0;
            }
          `}
        >
          {CONTENTS.map((v, i) => (
            <motion.div
              css={css`
                width: 100%;
                z-index: 500;
                position: relative;
              `}
              key={i}
              initial={{ opacity: 0, y: 30, backdropFilter: 'blur(0px)', borderRadius: 32 }}
              whileInView={{ opacity: 1, y: 0, backdropFilter: 'blur(16px)' }}
              transition={{ duration: 0.3, ease: 'easeIn', delay: ((i + 1) * 50) / 1000 }}
            >
              <Card
                key={i}
                css={css`
                  width: 100%;
                  z-index: 500;
                  position: relative;
                `}
              >
                <I type="solid" size={40}>
                  {v.icon}
                </I>
                <div
                  css={css`
                    gap: 8px;
                    display: flex;
                    flex-direction: column;
                  `}
                >
                  <Font type="Heading3">{v.name}</Font>
                  <Font type="Body">{v.text}</Font>
                </div>
              </Card>
            </motion.div>
          ))}
        </Container>
      </Layout>
      <div
        css={css`
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background-image: url(${ImageAsset.convertNrnToUri(nrns.ORANGE_BG_IMAGE)});
          background-position: top right;
          background-size: 130%;
          ${media.md} {
            background-size: 100%;
          }
          z-index: 1;
        `}
      />
    </div>
  );
};
const I = styled(Icon)`
  width: 40px;
  height: 40px;
  font-size: 40px;
  ${media.sm} {
    width: 48px;
    height: 48px;
    font-size: 48px;
  }
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  border-radius: 32px;
  border: 1px solid ${({ theme }) => theme.colorTheme.opacity05};
  background: ${({ theme }) => theme.colorTheme.opacity05};

  gap: 24px;
  height: 100%;
  width: 100%;
  ${media.md} {
    max-width: 388px;
  }
`;

export default Section4;
