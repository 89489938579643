import { ethers } from 'ethers';

/**
 * 지갑 패턴 정규식
 * @ReDoS safe
 * @link https://regexr.com/7nifp
 */
export const ETH_WALLET_ADDRESS = /0x[a-fA-F0-9]{40}/;

/**
 * @description contract에서 받아온 wei => slice 처리
 * @param value wei
 * @returns "~.xx"
 */
export const convertFixedData = (value: string | undefined) => {
  if (value) {
    const index = value.indexOf('.');
    return index > 0 ? value.slice(0, index + 3) : value;
  }
};
/**
 * @description value를 slice 하여서 처리
 * @param value string 값
 * @returns slice 된 string
 */
export const convertOriginalToWei = (value: string) => {
  if (value) {
    const length = value?.length;
    const dot = length > 18 ? '.' : '';
    let result = '';
    if (length > 18) {
      result = `${value.slice(0, -18)}${dot}${value.slice(-18)}`;
    } else {
      result = ethers.utils.formatUnits(value, 'ether');
    }
    return result;
  }
};
/**
 * @description param을 wei로 변환 ex) amount * 10^0 -> amount * 10^18
 * @param amount string값
 * @returns
 */
export const convertToWei = (amount: string) => {
  const AMOUNT = ethers.utils.formatUnits(
    ethers.utils.parseUnits(amount, 'ether')._hex.toString(),
    'wei',
  );
  return AMOUNT;
};

/**
 * @description param(wei형태의 string)을 bignumber로 변환
 * @param value string값
 * @returns
 */
export const convertBigNumber = (value: string) => {
  return ethers.BigNumber.from(value);
};

/**
 * @description 지갑주소를 slice 처리
 * @param addres 지갑주소
 * @returns   "0x1234...5678"
 */
export const reduceContractAdress = (addres: string) => {
  const regex = /^(0x.{4}).*(.{4})$/;
  return addres.replace(regex, '$1...$2');
};
