import { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
// components
import Layout from 'Components/Common/Layout';
import Font from 'Components/Atoms/Font';
import Container from 'Components/Atoms/Container';
import StoreButton from 'Components/Common/StoreButton';
// import QrCode from 'Components/Common/QrCode';
import AosComponent from 'Components/Atoms/AosComponent';
import Video from 'Components/Home/section1/Video';
import StickyVideo from 'Components/Home/section1/StickyVideo';
import Section2 from 'Components/Home/Section2';
// constants
import { nrns } from 'Constants/nrn';
// styles
import { media } from 'Styles/neptoTheme';
// util
import { checkMobile, checkMobileType } from 'Util/checkMobile';

const Section1: FC = () => {
  return (
    <Layout
      css={css`
        ${media.md} {
          height: auto;
        }
      `}
    >
      <AosComponent>
        <StickyVideo src="/video/section1.mp4" />

        <Container
          containerCss={css`
            /* overflow: hidden; */
            padding: 0;
            ${media.sm} {
              padding: 52px 0;
            }
            ${media.lg} {
              padding: 0;
            }
          `}
        >
          <Wrapper>
            <FontWrapper>
              <GradientFont>
                <Font type="Display1">Web3</Font>
                <br />
                <Font type="Display1">SocialFi</Font>
                <br />
                <Font type="Display1">Messenger</Font>
              </GradientFont>

              <Font
                as="h3"
                type="Heading3"
                textColor="shade800"
                style={css`
                  max-width: 589px;
                `}
              >
                Send token-attached messages to any wallet on blockchain
              </Font>
              <Font
                as="h3"
                type="Strong1"
                textColor="shade800"
                style={css`
                  margin-top: 56px;
                  max-width: 589px;
                `}
              >
                Coming Soon on
              </Font>
              <div
                css={css`
                  align-items: baseLine;
                  margin-top: 24px;
                  display: flex;
                  gap: 24px;
                  flex-direction: column;
                  ${media.sm} {
                    /* TODO: QR코드주석제거시 justify-content:center속성제거 */
                    justify-content: center;
                    align-items: center;
                  }
                  ${media.md} {
                    flex-direction: row;
                  }
                  ${media.lg} {
                    /* TODO: QR코드주석제거시 justify-content:center속성제거 */
                    justify-content: flex-start;
                  }
                `}
              >
                {/* TODO: 이후 QR코드 주석제거 */}
                {/* <CodeWrapper>
                  <QrCode />
                </CodeWrapper> */}
                <StoreBtnWrapper>
                  <StoreBtn
                    storeType="apple"
                    nrn={nrns.APP_STORE_SYMBOL_LIGHT}
                    text="App Store"
                    style={css`
                      padding: 16px 24px;
                      ${media.sm} {
                        padding: 20px 32px;
                      }
                      ${media.lg} {
                        padding: 24px 40px;
                      }
                    `}
                    className={checkMobile() && checkMobileType() === 'ios' ? 'visible' : ''}
                  />
                  <StoreBtn
                    storeType="google"
                    nrn={nrns.GOOGLE_PLAY_SYMBOL}
                    text="Google Play"
                    style={css`
                      padding: 16px 24px;
                      ${media.sm} {
                        padding: 20px 32px;
                      }
                      ${media.lg} {
                        padding: 24px 40px;
                      }
                    `}
                    className={checkMobile() && checkMobileType() === 'android' ? 'visible' : ''}
                  />
                </StoreBtnWrapper>
              </div>
            </FontWrapper>

            <Video src="/video/section1.mp4" />
          </Wrapper>

          <Section2 />
        </Container>
      </AosComponent>
    </Layout>
  );
};

const Wrapper = styled.div`
  ${media.xs} {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    padding: 0 24px;
    // 해당 섹션의 padding-top 만큼 Height 제거
    height: calc(100vh - 106px - 52px);
    min-height: 600px;
    position: relative;
    top: -30vh;
  }
  ${media.sm} {
    display: flex;
    align-items: center;
  }
  ${media.lg} {
    position: static;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const FontWrapper = styled.div`
  ${media.xs} {
    position: relative;
    max-width: 380px;
    z-index: 2;
  }
  ${media.sm} {
    text-align: center;
    max-width: 553px;
  }
  ${media.md} {
    /* transform: translateY(-90px); */
    max-width: 589px;
    text-align: center;
  }
  ${media.lg} {
    transform: translateY(0);
    text-align: left;
    position: relative;
    max-width: 800px;
  }
`;

const GradientFont = styled.div`
  background: radial-gradient(257.28% 104.83% at 0% 0%, #64acff 0%, #7244f1 55%, #cc5af6 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 16px;
  span {
    line-height: normal;
  }
`;

const StoreBtn = styled(StoreButton)`
  display: flex;
  ${media.xs} {
    :not(.visible) {
      display: none;
    }
  }
  ${media.sm} {
    :not(.visible) {
      display: flex;
    }
    display: flex !important;
  }
  button {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
`;

const StoreBtnWrapper = styled.div`
  display: flex;
  gap: 24px;
`;
export default Section1;
