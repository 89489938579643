import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
// style
import { media } from 'Styles/neptoTheme';

/** 모바일에서 보는 경우 띄워주는 Orb */
const StickyVideo = ({ src, width }: props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isLowPowerMode, setIsLowPowerMode] = useState(false);

  useEffect(() => {
    // autoPlay를 떼고, play 호출하는 방식으로 변경
    // catch 걸리면 저전력 모드로 판단하고 이미지로 변환
    videoRef.current && videoRef.current.play().catch(() => setIsLowPowerMode(true));
  }, []);

  return (
    <Container>
      {!isLowPowerMode ? (
        <StyledVideo ref={videoRef} src={src} loop muted playsInline width={width ?? 612} />
      ) : (
        <LowPowerModeImage src="/images/lowPowerMode/loadingOrb.png" />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: sticky !important;
  z-index: -1;
  top: 20%;
  height: 400vh !important;

  ${media.xs} {
    z-index: -1;
    display: flex;
    position: relative;
    flex: 1;
    width: 100%;
    height: 100%;
    max-height: 340px;
  }

  ${media.sm} {
    max-height: 480px;
  }

  ${media.lg} {
    display: none;
  }
`;

const commonStyles = css`
  max-width: 480px;
  position: absolute;
  top: 50%;
  left: 50%;

  ${media.xs} {
    width: 100%;
    max-width: 480px;
    transform: translate(-50%, -55%);
  }

  ${media.sm} {
    transform: translate(-50%, -40%);
  }

  ${media.md} {
    transform: translate(-50%, -50%);
  }
`;

const StyledVideo = styled.video`
  ${commonStyles}
`;

const LowPowerModeImage = styled.img`
  ${commonStyles}
`;

interface props {
  src: string;
  width?: number;
}

export default StickyVideo;
