import Axios from './axios';

export const registerEmail = async (email: string) => {
  const res = await Axios({
    method: 'POST',
    url: 'https://email.nepto.com/subscription/register',
    params: {
      email,
    },
  });
  return res;
};
