import { FC } from 'react';
import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';
// lib
import ImageAsset from 'Lib/Model/Asset/image';
// components
import Font from 'Components/Atoms/Font';

const StoreButton: FC<Props> = ({ storeType, nrn, text, style, className }) => {
  return (
    <Button
      $storeType={storeType}
      css={style}
      className={className}
      disabled // TODO 넵토 출시 후 disabled 해제
    >
      <img
        src={ImageAsset.convertNrnToUri(nrn, {
          format: 'webp',
          height: 80,
        })}
        css={css`
          height: 40px;
        `}
        alt="store-image"
      />
      <Font type="Button" textColor="shade900">
        {text}
      </Font>
    </Button>
  );
};

const Button = styled.button<{ $storeType: 'apple' | 'google' }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  border: 1px solid ${({ theme }) => theme.colorTheme.shade900};
  border-radius: 100px;
  background-color: transparent;
  color: ${({ theme }) => theme.colorTheme.shade900};
  filter: none;
  transition: all 0.2s;

  &:hover:not([disabled]) {
    background-color: ${({ theme }) => theme.colorTheme.shade900};
    span {
      color: ${({ theme }) => theme.colorTheme.shade000};
    }
    img {
      filter: ${({ $storeType }) =>
        $storeType === 'apple' ? 'grayscale(100%) brightness(0)' : 'none'};
    }
  }

  &[disabled] {
    cursor: not-allowed;
    border: 1px solid ${({ theme }) => theme.colorTheme.shade300} !important;
    background-color: ${({ theme }) => theme.colorTheme.shade200} !important;
  }
`;

interface Props {
  className?: string;
  style?: SerializedStyles;
  storeType: 'apple' | 'google';
  nrn: NRN;
  text: string;
}

export default StoreButton;
