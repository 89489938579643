import { css } from '@emotion/react';
import { FC, ReactNode } from 'react';

const AosComponent: FC<Props> = ({
  children,
  aosType = 'fade-up',
  aosDelay = '200',
  aosDuration = '1000',
  aosCss,
}) => (
  <div data-aos={aosType} data-aos-delay={aosDelay} data-aos-duration={aosDuration} css={aosCss}>
    {children}
  </div>
);

interface Props {
  children: ReactNode;
  aosType?: string;
  aosDelay?: string | number;
  aosDuration?: string;
  aosCss?: ReturnType<typeof css>;
}

export default AosComponent;
