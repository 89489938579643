export const allEmptyChecker = (keyword: string): boolean => {
  if (keyword === '' || ~keyword.indexOf(' ')) {
    return false;
  }
  return true;
};

export const validationEmailChecker = (keyword: string) => {
  const regExp = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/i;
  if (keyword === '') {
    return {
      valid: true,
      text: '',
    };
  }

  if (!regExp.test(keyword)) {
    return {
      valid: false,
      text: 'Invalid email address',
    };
  }

  return {
    valid: true,
    text: '',
  };
};
