/**
 * NRN 상수
 * @description `Link`를 참고하여 필요한 NRN을 작성합니다.
 * @link https://www.notion.so/alocados/Nepto-Asset-ce3141de82f344e4b49c7ba3394481da?pvs=4
 */

export const nrns: Record<NRN_KEYS, NRN> = {
  NEPTO_SYMBOL:
    'nepto:file:prod:64bf5e86b91e7fcd9c1220c7:file:image/internal-upload/original/9f067b0fbc2849d0baaf72d0095a6ef4.png',
  NEPTO_LIGHT_DARK:
    'nepto:file:dev:64e2e38c70b3168e29ac1062:file:image/internal-upload/original/ab2c3a83cefb4032916d5a6f58d00689.png',
  APP_STORE_SYMBOL_DARK:
    'nepto:file:staging:NEPTO:internal-file:image/original/intro-app-store-symbol-dark.png',
  APP_STORE_SYMBOL_LIGHT:
    'nepto:file:staging:NEPTO:internal-file:image/original/intro-app-store-symbol-light.png',
  GOOGLE_PLAY_SYMBOL:
    'nepto:file:staging:NEPTO:internal-file:image/original/intro-google-play-symbol.png',
  X_SYMBOL: 'nepto:file:staging:NEPTO:internal-file:image/original/intro-x-symbol.png',
  TELEGRAM_SYMBOL:
    'nepto:file:staging:NEPTO:internal-file:image/original/intro-telegram-symbol.png',
  LINK_SYMBOL: 'nepto:file:staging:NEPTO:internal-file:image/original/intro-link-symbol.png',
  AD_MESSAGE_IMAGE:
    'nepto:file:staging:NEPTO:internal-file:image/original/intro-ad-message-image.png',
  DIRECT_MESSAGE_IMAGE_MOBILE:
    'nepto:file:staging:NEPTO:internal-file:image/original/intro-direct-message-image-mobile.webp',
  DIRECT_MESSAGE_IMAGE_MOBILE_ORIGINAL:
    'nepto:file:staging:NEPTO:internal-file:image/original/intro-direct-message-image-mobile-original.webp',
  DIRECT_MESSAGE_IMAGE:
    'nepto:file:staging:NEPTO:internal-file:image/original/intro-direct-message-image.webp',
  DIRECT_MESSAGE_IMAGE_ORIGINAL:
    'nepto:file:staging:NEPTO:internal-file:image/original/intro-direct-message-image-original.webp',
  BLUE_BG_IMAGE: 'nepto:file:staging:NEPTO:internal-file:image/original/intro-blue-bg-image.png',
  ORANGE_BG_IMAGE:
    'nepto:file:staging:NEPTO:internal-file:image/original/intro-orange-bg-image.png',
  CONNECT_WALLET_OWNERS:
    'nepto:file:staging:NEPTO:internal-file:image/original/connect-wlallet-owners.png',
  SEND_ANNOUNCEMENT_MESSAGE:
    'nepto:file:staging:NEPTO:internal-file:image/original/send-announcement-message.png',
  ADVERTISE_TO_TARGETED:
    'nepto:file:staging:NEPTO:internal-file:image/original/advertise-to-targeted.png',
  NFT_WORLDCOIN: 'nepto:file:staging:NEPTO:internal-file:image/original/intro-nft-worldcoin.webp',
  NFT_UNISWAP: 'nepto:file:staging:NEPTO:internal-file:image/original/intro-nft-uniswap.webp',
  NFT_SHIBAINU: 'nepto:file:staging:NEPTO:internal-file:image/original/intro-nft-shibainu.webp',
  NFT_RIDODAO: 'nepto:file:staging:NEPTO:internal-file:image/original/intro-nft-ridodao.webp',
  NFT_PUDGY_PENGUINS:
    'nepto:file:staging:NEPTO:internal-file:image/original/intro-nft-pudgy-penguins.webp',
  NFT_MOONBIRDS: 'nepto:file:staging:NEPTO:internal-file:image/original/intro-nft-moonbirds.webp',
  NFT_DEGODS: 'nepto:file:staging:NEPTO:internal-file:image/original/intro-nft-degods.webp',
  NFT_CLONEX: 'nepto:file:staging:NEPTO:internal-file:image/original/intro-nft-clonex.webp',
  NFT_AZUKI: 'nepto:file:staging:NEPTO:internal-file:image/original/intro-nft-azuki.webp',
  CARD_TOKENGATE:
    'nepto:file:staging:NEPTO:internal-file:image/original/intro-section9-image-tokengate.png',
  CARD_VOTE: 'nepto:file:staging:NEPTO:internal-file:image/original/intro-section9-image-vote.webp',
  CARD_WEB3: 'nepto:file:staging:NEPTO:internal-file:image/original/intro-section9-image-web3.png',
  CARD_TOKENGATE_COLOR:
    'nepto:file:staging:NEPTO:internal-file:image/original/intro-section9-image-tokengate-color.webp',
  CARD_VOTE_COLOR:
    'nepto:file:staging:NEPTO:internal-file:image/original/intro-section9-image-vote-color.webp',
  CARD_WEB3_COLOR:
    'nepto:file:staging:NEPTO:internal-file:image/original/intro-section9-image-web3-color.webp',
  PINK_BG_IMAGE: 'nepto:file:staging:NEPTO:internal-file:image/original/intro-pink-bg-image.png',
  HEXAGON_BG_IMAGE:
    'nepto:file:staging:NEPTO:internal-file:image/original/intro-section11-bg-hexagon.png',
  QR_CODE: 'nepto:file:staging:NEPTO:internal-file:image/original/intro-qr-code.png',
  WALLET_MOBILE: 'nepto:file:staging:NEPTO:internal-file:image/original/intro-wallet-mobile.webp',
};

type NRN_KEYS =
  | 'NEPTO_SYMBOL'
  | 'NEPTO_LIGHT_DARK'
  | 'APP_STORE_SYMBOL_DARK'
  | 'APP_STORE_SYMBOL_LIGHT'
  | 'GOOGLE_PLAY_SYMBOL'
  | 'X_SYMBOL'
  | 'TELEGRAM_SYMBOL'
  | 'LINK_SYMBOL'
  | 'AD_MESSAGE_IMAGE'
  | 'DIRECT_MESSAGE_IMAGE'
  | 'DIRECT_MESSAGE_IMAGE_ORIGINAL'
  | 'DIRECT_MESSAGE_IMAGE_MOBILE'
  | 'DIRECT_MESSAGE_IMAGE_MOBILE_ORIGINAL'
  | 'BLUE_BG_IMAGE'
  | 'ORANGE_BG_IMAGE'
  | 'CONNECT_WALLET_OWNERS'
  | 'SEND_ANNOUNCEMENT_MESSAGE'
  | 'ADVERTISE_TO_TARGETED'
  | 'NFT_WORLDCOIN'
  | 'NFT_UNISWAP'
  | 'NFT_SHIBAINU'
  | 'NFT_RIDODAO'
  | 'NFT_PUDGY_PENGUINS'
  | 'NFT_MOONBIRDS'
  | 'NFT_DEGODS'
  | 'NFT_CLONEX'
  | 'NFT_AZUKI'
  | 'CARD_TOKENGATE'
  | 'CARD_VOTE'
  | 'CARD_WEB3'
  | 'CARD_TOKENGATE_COLOR'
  | 'CARD_VOTE_COLOR'
  | 'CARD_WEB3_COLOR'
  | 'PINK_BG_IMAGE'
  | 'PINK_BG_IMAGE'
  | 'HEXAGON_BG_IMAGE'
  | 'QR_CODE'
  | 'WALLET_MOBILE';
