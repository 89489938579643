import { forwardRef, Ref, useState, FocusEvent, InputHTMLAttributes } from 'react';
import styled from '@emotion/styled';
// style
import { media, mediaQuery } from 'Styles/neptoTheme';
import Font from './Font';
import { css } from '@emotion/react/dist/emotion-react.cjs';

// eslint-disable-next-line react/display-name
const Input = forwardRef(
  (
    { containerStyle, isError, helperText, ...rest }: Props & InputHTMLAttributes<HTMLInputElement>,
    ref?: Ref<HTMLInputElement>,
  ) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
      setIsFocused(true);
      if (rest.onFocus) {
        rest.onFocus(e);
      }
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      if (rest.onBlur) {
        rest.onBlur(e);
      }
    };

    return (
      <InputContainer css={containerStyle}>
        <StyledInput
          value={rest.value}
          ref={ref}
          $isError={isError}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...rest}
          placeholder=""
        />
        <Label
          htmlFor="address"
          className={`input-label ${isFocused ? 'top focused' : rest.value ? 'top' : ''}`}
          $isError={isError}
        >
          <Font type="Body">{rest.placeholder}</Font>
        </Label>
        {helperText && <HelperText $error={isError}>{helperText}</HelperText>}
      </InputContainer>
    );
  },
);

const InputContainer = styled.div`
  width: 100%;
  position: relative;
`;

const StyledInput = styled.input<{ $isError?: boolean }>`
  background-color: ${({ theme }) => theme.colorTheme.shade000};
  border-radius: 16px;
  padding: 14px 24px;
  width: 100%;
  height: 84px;
  border: 2px solid transparent;
  transition: all 0.3s cubic-bezier(0.17, 0.67, 0.45, 1);
  ${({ theme }) => mediaQuery(theme.breakPoints.sm)} {
    height: 84px;
  }

  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings:
    'cv09' on,
    'cv04' on,
    'cv03' on;
  font-weight: 400;
  line-height: 150%;
  font-size: 20px;
  ${media.lg} {
    font-size: 24px;
  }

  :focus {
    border: ${({ $isError, theme }) =>
      $isError
        ? `2px solid ${theme.colorTheme.errorFont}`
        : `2px solid ${theme.colorTheme.primary100}`};
  }

  &:not(:placeholder-shown),
  &:focus {
    padding: 32px 16px 10px 16px;
    ${media.sm} {
      padding: 35px 24px 10px 24px;
    }
  }
`;

const HelperText = styled.div<{ $error?: boolean }>`
  padding: 8px 0 0 24px;
  color: ${({ $error, theme }) =>
    $error ? theme.colorTheme.errorFont : theme.colorTheme.successFont};
`;

const Label = styled.label<{ $isError?: boolean; $isSuccess?: boolean }>`
  position: absolute;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.17, 0.67, 0.45, 1);
  transform-origin: top left;
  left: 0;
  top: 50%;
  transform: ${({ $isError }) => ($isError ? 'translate(24px, -100%)' : 'translate(24px, -50%)')};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  font-size: 18px;
  ${media.lg} {
    font-size: 24px;
  }

  &.top,
  &.focused {
    top: 5px;
    transform: scale(0.75) translate(24px, 8px);
    font-size: 14px;
    ${media.sm} {
      transform: scale(0.75) translate(36px, 14px);
    }
    ${media.lg} {
      transform: scale(0.75) translate(36px, 10px);
    }
    span {
      color: ${({ $isError, theme }) =>
        $isError ? theme.colorTheme.errorFont : theme.colorTheme.primaryFont} !important;
    }
  }
  &:not(.focused) {
    span {
      color: ${({ $isError, theme }) => $isError && theme.colorTheme.errorFont} !important;
    }
  }
`;

interface Props {
  isSuccess?: boolean;
  successMsg?: string;
  isError?: boolean;
  helperText?: string;
  containerStyle?: ReturnType<typeof css>;
}

export default Input;
