import { forwardRef, ReactNode, Ref } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

// eslint-disable-next-line react/display-name
const Container = forwardRef(
  ({ children, containerCss, id, ...rest }: Props, ref?: Ref<HTMLDivElement>) => {
    return (
      <Wrapper ref={ref} id={id} css={containerCss} {...rest}>
        {children}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.breakPoints.lg}px;
  margin: 0 auto;
`;
interface Props {
  children: ReactNode;
  id?: string;
  containerCss?: ReturnType<typeof css>;
}
export default Container;
