/**
 * 아이폰 안드로이드 체크
 * @returns 아이폰 / 안드로이드
 */
export const checkMobileType = (): 'android' | 'ios' | '' => {
  const model = navigator.userAgent.toLocaleLowerCase(); // 아이폰 안드로이드 기종 소문자로 변환

  if (model.indexOf('android') > -1) {
    return 'android';
  } else if (model.indexOf('iphone') > -1) {
    return 'ios';
  } else return '';
};

/**
 * 모바일 여부
 * @returns 모바일이면 true 아니라면 false
 */
export const checkMobile = () => {
  const mobileType = checkMobileType();
  if (mobileType === 'android' || mobileType === 'ios') {
    return true;
  } else {
    return false;
  }
};
