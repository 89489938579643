// lib
import Asset from 'Lib/Model/Asset';
// type
import type { AssetArgs, NrnOptions } from 'Types/nrn';

/**
 * ImageAsset
 * @class
 * @example
 * new ImageAsset({ uri: someUri, nrnCategory: 'user-profile', nrnFileType: 'image' }).getNrn();
 * ImageAsset.conventNrnToUri("${NEPTO_DEEP_LINK_SCHEME}://...", { format: 'webp', width: 40 * 2  });
 * ImageAsset.convertUriToNrn({ uri: "https://,,,", nrnCategory: 'user-profile' });
 */
class ImageAsset extends Asset {
  /**
   * NRN 이미지의 URI으로 변환해주는 함수
   */
  static convertNrnToUri(nrn: NRN, options?: NrnOptions): string {
    const uri = new Asset({ nrn, nrnOptions: options }).uri!;
    return uri;
  }

  constructor({ nrn, nrnOptions }: Args) {
    super({ nrn, nrnOptions });
  }
}

interface Args extends AssetArgs {}

export default ImageAsset;
