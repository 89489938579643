import axios from 'axios';

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
});

/**
 * Request 성공 handler
 */
const requestSuccessHandler = config => {
  return config;
};

/**
 * Request 실패 handler
 */
const requestErrorHandler = err => {
  return Promise.reject(err);
};

/**
 * Response 성공 handler
 */
const responseSuccessHandler = res => res;

/**
 * Response 실패 handler
 * @param err 에러 객체
 */
const responseErrorHandler = async ({ response }) => {
  return response;
};

/**
 * Axios Request 미들웨어
 */
Axios.interceptors.request.use(
  config => requestSuccessHandler(config),
  err => requestErrorHandler(err),
);

/**
 * Axios Response 미들웨어
 */
Axios.interceptors.response.use(
  res => responseSuccessHandler(res),
  err => responseErrorHandler(err),
);
export default Axios;
