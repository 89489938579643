import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';
import { Link } from 'react-router-dom';
// components
import Font from 'Components/Atoms/Font';
import Container from 'Components/Atoms/Container';
import Layout from 'Components/Common/Layout';
import Icon from 'Components/Atoms/Icon';
// lib
import ImageAsset from 'Lib/Model/Asset/image';
// constatns
import { nrns } from 'Constants/nrn';
// styles
import { breakPoints, mediaQuery } from 'Styles/neptoTheme';
// type
import type { FC } from 'react';

const OgBanner: FC = () => {
  const { breakPoints } = useTheme();

  const [isSecondBannerOpened, setIsSecondBannerOpened] = useState(false);

  useEffect(() => {
    // 2024년 4월 26일, 14:00(UTC) 부터 보여지도록 설정
    const visibleDate = new Date(Date.UTC(2024, 3, 26, 14, 0, 0));
    const now = new Date();

    if (now >= visibleDate) setIsSecondBannerOpened(true);
  }, []);

  return (
    <Layout>
      <Container
        containerCss={css`
          padding: 160px 0 !important;
          @media (max-width: ${breakPoints.lg}px) {
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 160px 24px !important;
          }
          @media (max-width: ${breakPoints.md}px) {
            padding: 160px 24px !important;
          }
        `}
      >
        <PostWrapper>
          {/* OG Title Image */}
          <img
            src="/images/ogbanner/og-big-title.png"
            css={css`
              z-index: 5;
              position: absolute;
              ${mediaQuery(breakPoints.xs)} {
                width: 227px;
                height: 132px;
                top: -60px;
              }
              ${mediaQuery(breakPoints.sm)} {
                width: 355px;
                height: 201px;
                top: -80px;
              }
              ${mediaQuery(breakPoints.md)} {
                width: 428px;
                height: 248px;
                top: -124px;
              }
            `}
          />
          <Wrapper>
            {/* Left Banner Image */}
            <img
              src="/images/ogbanner/og-left.png"
              css={css`
                z-index: 3;
                height: 574px;
                width: 638px;
                position: absolute;
                left: 0;
                top: 0;
              `}
            />
            {/* Nepto Logo Image */}
            <img
              src={ImageAsset.convertNrnToUri(nrns.NEPTO_LIGHT_DARK, {
                format: 'webp',
                height: 102,
              })}
              css={css`
                z-index: 10;
                height: 56px;
              `}
              alt="nepto-logo"
            />
            {/* Right Banner Image  */}
            <img
              src="/images/ogbanner/og-right.png"
              css={css`
                z-index: 3;
                height: 476px;
                width: 450px;
                position: absolute;
                top: -52px;
                right: -1px;
                display: none;
                ${mediaQuery(breakPoints.md)} {
                  display: block;
                }
              `}
            />
            <TextWrapper>
              <Font
                as="h2"
                type="Heading3"
                textColor="white"
                style={css`
                  z-index: 5;
                `}
              >
                {isSecondBannerOpened
                  ? 'NEPTO OG Registration will return again.'
                  : 'NEPTO OG Member Registration'}
              </Font>
              <Font
                as="h2"
                type={isSecondBannerOpened ? 'Body' : 'Strong2'}
                textColor="opacity76"
                style={css`
                  z-index: 5;
                `}
              >
                {isSecondBannerOpened
                  ? 'Please visit Nepto community for more information.'
                  : 'Become our OG member to receive exclusive early benefits'}
              </Font>
            </TextWrapper>
            <EndsContent>
              {!isSecondBannerOpened && (
                <>
                  <Font
                    as="h2"
                    type="Strong2"
                    textColor="white"
                    style={css`
                      display: none;
                      ${mediaQuery(breakPoints.sm)} {
                        display: block;
                      }
                    `}
                  >
                    ENDS
                  </Font>
                  <Font
                    as="h2"
                    type="Caption2"
                    textColor="white"
                    style={css`
                      display: block;
                      ${mediaQuery(breakPoints.sm)} {
                        display: none;
                      }
                    `}
                  >
                    ENDS
                  </Font>
                </>
              )}
              <Font as="h2" type="Display4" textColor="white">
                {isSecondBannerOpened ? 'Coming Soon' : 'April 26 14:00'}
              </Font>
              {!isSecondBannerOpened && (
                <>
                  <Font
                    as="h2"
                    type="Strong2"
                    textColor="white"
                    style={css`
                      display: none;
                      ${mediaQuery(breakPoints.sm)} {
                        display: block;
                      }
                    `}
                  >
                    (UTC)
                  </Font>
                  <Font
                    as="h2"
                    type="Caption2"
                    textColor="white"
                    style={css`
                      display: block;
                      ${mediaQuery(breakPoints.sm)} {
                        display: none;
                      }
                    `}
                  >
                    (UTC)
                  </Font>
                </>
              )}
            </EndsContent>
            <Button
              to={
                isSecondBannerOpened
                  ? 'https://t.me/nepto_official'
                  : 'https://zad4wc7lbso.typeform.com/to/YA5rnSQH'
              }
              target="_blank"
            >
              <Font as="h2" type="Button" textColor="white">
                {isSecondBannerOpened ? 'Visit Community' : 'Register'}
              </Font>
              {/* <Icon size={32} type="solid" color="white">
                {isSecondBannerOpened ? 'Open In New' : 'Pen'}
              </Icon> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  // fill-rule="evenodd"
                  // clip-rule="evenodd"
                  d="M13.4672 4.00002L13.6297 4.00003C14.366 4.00003 14.963 4.59699 14.963 5.33337C14.963 6.06975 14.366 6.6667 13.6297 6.6667C12.0528 6.6667 11.4547 6.67288 10.9824 6.76469C8.84825 7.17953 7.17953 8.84825 6.76469 10.9824C6.67288 11.4547 6.6667 12.0528 6.6667 13.6297V16C6.6667 17.8889 6.66774 19.2253 6.7531 20.2701C6.83719 21.2993 6.99642 21.9276 7.248 22.4213C7.75932 23.4248 8.57522 24.2407 9.57875 24.7521C10.0725 25.0036 10.7007 25.1629 11.73 25.247C12.7747 25.3323 14.1112 25.3334 16 25.3334H18.3704C19.9472 25.3334 20.5453 25.3272 21.0177 25.2354C23.1518 24.8205 24.8205 23.1518 25.2354 21.0177C25.3272 20.5453 25.3334 19.9472 25.3334 18.3704C25.3334 17.634 25.9303 17.0371 26.6667 17.0371C27.4031 17.0371 28 17.634 28 18.3704L28.0001 18.5329C28.0003 19.8914 28.0004 20.7684 27.8531 21.5265C27.2308 24.7277 24.7277 27.2308 21.5265 27.8531C20.7684 28.0004 19.8914 28.0003 18.5329 28.0001L18.3704 28H16H15.9417H15.9415C14.1242 28 12.6789 28.0001 11.5128 27.9048C10.3187 27.8072 9.30044 27.6031 8.36811 27.1281C6.86281 26.3611 5.63897 25.1373 4.87198 23.632L6.05999 23.0266L4.87198 23.632C4.39694 22.6996 4.19286 21.6814 4.09529 20.4873C4.00001 19.3211 4.00002 17.8758 4.00003 16.0584L4.00003 16V13.6297L4.00002 13.4672L4.00002 13.4672C3.9998 12.1086 3.99965 11.2317 4.14702 10.4736C4.76927 7.27235 7.27235 4.76927 10.4736 4.14702C11.2317 3.99965 12.1086 3.9998 13.4672 4.00002L13.4672 4.00002ZM18.6667 6.6667C17.9303 6.6667 17.3334 6.06975 17.3334 5.33337C17.3334 4.59699 17.9303 4.00003 18.6667 4.00003H26.6665H26.6667C27.0305 4.00003 27.3602 4.14571 27.6008 4.38189C27.6066 4.38764 27.6124 4.39345 27.6182 4.3993C27.7407 4.52407 27.8337 4.66696 27.8973 4.81924C27.9635 4.97746 28 5.15114 28 5.33337V13.3334C28 14.0697 27.4031 14.6667 26.6667 14.6667C25.9303 14.6667 25.3334 14.0697 25.3334 13.3334V8.55232L16.9428 16.9428C16.4221 17.4635 15.5779 17.4635 15.0572 16.9428C14.5365 16.4221 14.5365 15.5779 15.0572 15.0572L23.4478 6.6667H18.6667Z"
                  fill="#ffffff"
                />
              </svg>
            </Button>
          </Wrapper>
        </PostWrapper>
      </Container>
    </Layout>
  );
};

const PostWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  padding: 36px 24px;
  border-radius: 72px;
  background: radial-gradient(321.23% 111.8% at 0% 0%, #5d28f2 46.27%, #3100bc 100%),
    radial-gradient(228.05% 113.44% at 100% 0%, #45d2ff 0%, #4f95ff 35.67%, #5d28f2 100%);
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  height: auto;
  gap: 24px;
  overflow: hidden;
  padding: 36px 16px;
  ${mediaQuery(breakPoints.sm)} {
    padding: 36px 24px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 4px;
`;

const EndsContent = styled.div`
  border-radius: 24px;
  display: flex;
  background-color: ${({ theme }) => theme.colorTheme.shade200};
  padding: 12px 16px;
  align-items: baseline;
  z-index: 5;
  margin-bottom: 8px;
  gap: 4px;
  ${mediaQuery(breakPoints.sm)} {
    padding: 12px 32px;
    gap: 10px;
  }
`;

const Button = styled(Link)`
  padding: 16px 28px;
  gap: 12px;
  display: flex;
  flex-direction: row;
  border: 1px solid ${({ theme }) => theme.colorTheme.white};
  border-radius: 100px;
  z-index: 5;
  transition: all 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.colorTheme.shade900};
    h2 {
      color: ${({ theme }) => theme.colorTheme.primary100};
    }
    i {
      color: ${({ theme }) => theme.colorTheme.primary100};
    }
    path {
      fill: ${({ theme }) => theme.colorTheme.primary100};
    }
  }
`;

export default OgBanner;
