import { FC } from 'react';
import { Link } from 'react-router-dom';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
// components
import Font from 'Components/Atoms/Font';
// lib
import ImageAsset from 'Lib/Model/Asset/image';
// constants
import { nrns } from 'Constants/nrn';
// style
import { media, mediaQuery } from 'Styles/neptoTheme';

const FOOTER_LINKS = [
  // TODO: 넵토 앱 런칭 시 주석 롤백
  // {
  //   name: 'Whitepaper',
  //   link: '/whitepaper',
  // },
  // {
  //   name: 'Contact',
  //   link: '/contact',
  // },
  {
    name: 'Terms of Use',
    link: '/policy/terms',
  },
  {
    name: 'Privacy Policy',
    link: '/policy/privacy',
  },
];

const Footer: FC = () => {
  const SNS = [
    {
      id: 1,
      link: 'https://t.me/nepto_official',
      name: 'Telegram',
      symbol: nrns.TELEGRAM_SYMBOL,
      target: '_blank',
    },
    {
      id: 2,
      link: 'https://x.com/neptoMessenger',
      name: 'X.com',
      symbol: nrns.X_SYMBOL,
      target: '_blank',
    },
  ];
  const { breakPoints } = useTheme();
  return (
    <FooterContainer>
      <NeptoFooter>
        <div>
          <img
            src={ImageAsset.convertNrnToUri(nrns.NEPTO_LIGHT_DARK, {
              format: 'webp',
              width: 416,
            })}
            css={css`
              width: 114px;
              ${mediaQuery(breakPoints.md)} {
                width: 208px;
              }
            `}
            alt="nepto-footer-symbol"
          />
        </div>
        {/* TODO: 넵토 앱 런칭 시 주석 롤백 */}
        <div
          css={css`
            ${media.sm} {
              display: flex;
              gap: 16px;
            }
          `}
        >
          {SNS.map(sns => (
            <SnsLink to={sns.link} key={sns.id} target={sns.target}>
              <div
                css={css`
                  display: flex;
                  gap: 16px;
                  flex-direction: row;
                  align-items: center;
                `}
              >
                <img
                  src={ImageAsset.convertNrnToUri(sns.symbol, {
                    format: 'webp',
                    width: 64,
                  })}
                  css={css`
                    width: 28px;
                    ${media.md} {
                      width: 32px;
                    }
                  `}
                />
                <Font type="Title1">{sns.name}</Font>
                <img
                  src={ImageAsset.convertNrnToUri(nrns.LINK_SYMBOL, {
                    format: 'webp',
                    width: 48,
                  })}
                  css={css`
                    width: 24px;
                  `}
                />
              </div>
              <div
                css={css`
                  display: flex;
                  gap: 16px;
                  flex-direction: row;
                  align-items: center;
                `}
              >
                <img
                  src={ImageAsset.convertNrnToUri(sns.symbol, {
                    format: 'webp',
                    width: 64,
                  })}
                  css={css`
                    width: 32px;
                  `}
                />
                <Font type="Title1">{sns.name}</Font>
                <img
                  src={ImageAsset.convertNrnToUri(nrns.LINK_SYMBOL, {
                    format: 'webp',
                    width: 48,
                  })}
                  css={css`
                    width: 24px;
                  `}
                />
              </div>
            </SnsLink>
          ))}
        </div>

        <div
          css={css`
            display: flex;
          `}
        >
          <ul
            css={css`
              display: flex;
              flex-direction: column;
              gap: 24px;
            `}
          >
            {FOOTER_LINKS.map(link => (
              <li
                key={link.name}
                css={css`
                  transition: all 0.3s;
                  :hover {
                    opacity: 0.68;
                  }

                  :active {
                    scale: 0.9;
                  }
                `}
              >
                <a href={link.link}>
                  <Font type="Button">{link.name}</Font>
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div
          css={css`
            display: flex;
            justify-content: flex-end;

            gap: 24px;
            flex-wrap: wrap;
          `}
        >
          <div>
            <Font type="Caption1">Copyright 2023 OXYZ LTD.</Font>
          </div>
        </div>
      </NeptoFooter>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colorTheme.shade100};
  border-top: 1px solid ${({ theme }) => theme.colorTheme.shade200};
`;

const NeptoFooter = styled.footer`
  display: flex;
  flex-direction: column;
  gap: 56px;

  max-width: ${({ theme }) => theme.breakPoints.lg}px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colorTheme.shade100};
  padding: 60px 24px;
  ${({ theme }) => mediaQuery(theme.breakPoints.md)} {
    padding: 80px 64px;
  }
  ${({ theme }) => mediaQuery(theme.breakPoints.lg)} {
    padding: 80px 0;
  }
`;

const SnsLink = styled(Link)`
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  padding: 0 0 16px 0;
  ${({ theme }) => mediaQuery(theme.breakPoints.lg)} {
    height: 66px;
  }
  @media (max-height: 800px) and (min-width: 1200px) {
    height: 48px;
  }
  gap: 16px;
  margin-top: 16px;
  color: ${({ theme }) => theme.colorTheme.shade900};
  border-bottom: 1px solid ${({ theme }) => theme.colorTheme.shade300};

  position: relative;
  overflow: hidden;
  text-align: center;
  flex-grow: 1;
  animation-duration: 1s;
  animation-fill-mode: both;

  div {
    transition: transform 700ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    position: relative;
    will-change: transform;

    &:last-child {
      display: none;
      ${({ theme }) => mediaQuery(theme.breakPoints.lg)} {
        display: flex;
      }
    }
  }

  ${({ theme }) => mediaQuery(theme.breakPoints.lg)} {
    :hover {
      div {
        transform: translateY(calc(-100% - 16px));
      }
    }
  }

  :active {
    div {
      transform: scale(0.9);
    }
  }
`;

export default Footer;
