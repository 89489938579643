import { FC, forwardRef, HTMLAttributes, ReactNode, Ref } from 'react';
import styled from '@emotion/styled';
// style
import { media } from 'Styles/neptoTheme';

// eslint-disable-next-line react/display-name
const Layout = forwardRef(
  ({ children, ...rest }: Props & HTMLAttributes<HTMLDivElement>, ref?: Ref<HTMLDivElement>) => {
    return (
      <LayoutContainer ref={ref} {...rest}>
        {children}
      </LayoutContainer>
    );
  },
);

const LayoutContainer = styled.main`
  position: relative;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding-top: 88px; // Header height

  ${media.sm} {
    padding-top: 106px;
  }
`;

interface Props {
  children: ReactNode;
}

export default Layout;
