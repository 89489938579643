import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import bezierEasing from 'bezier-easing';
// components
import Font from 'Components/Atoms/Font';
import Container from 'Components/Atoms/Container';
import Layout from 'Components/Common/Layout';
// style
import { ColorType, media } from 'Styles/neptoTheme';

const TEXTS = [
  {
    title: 'TOKEN-ATTACHED MESSAGE',
    description:
      'Contact wallet owners by sending token-attached message to their wallet addresses',
    color: 'successFont',
    animation: {
      startPosition: -0.05,
      endPosition: 0.25,
      willChange: 'auto',
    },
  },
  {
    title: 'ON-CHAIN TRANSFER',
    description:
      'Attached tokens are partially transferred on-chain to the wallet, prompting owner to check message on Nepto',
    color: 'orangeFont',
    animation: {
      startPosition: 0.25,
      endPosition: 0.5,
      willChange: 'auto',
    },
  },
  {
    title: 'READ TO CLAIM',
    description: 'Recipients will claim remaining tokens on Nepto after message read',
    color: 'primaryFont',
    animation: {
      startPosition: 0.5,
      endPosition: 0.75,
      willChange: 'auto',
    },
  },
  {
    title: 'MULTISEND',
    description:
      'Reach out to as many as 1,000 distinct wallet addresses at once with a single message',
    color: 'infoFont',
    animation: {
      startPosition: 0.75,
      endPosition: 1,
      willChange: 'auto',
    },
  },
];

const Section3 = () => {
  const ease = bezierEasing(0.0, 0.64, 0.01, 0.99); // 빠르게 올라가다가 중간쯤에선 거의 유지
  const easeIn = bezierEasing(0.38, 0.11, 0.78, 0.13); // 처음부터 끝까지 빠르게
  const yEase = bezierEasing(0, 0.7, 1, 0.3); // 천천히 늘어나다가 확 높아짐
  const containerRef = useRef<HTMLDivElement>(null);
  const videoBoxRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(1);
  const videoRef = useRef<null[] | HTMLVideoElement[]>([]);
  const textRef = useRef<null[] | HTMLDivElement[]>([]);

  const getValueFromRate = (start, end, rate) => {
    return start + (end - start) * rate;
  };

  useLayoutEffect(() => {
    // 프레임마다 이미지를 미리 요청해둠
    const FRAME_COUNT = 4;
    const preloadVideo = () => {
      for (let i = 0; i < FRAME_COUNT; i++) {
        const image = new Image();
        image.src = `/video/section3/adm-30f-${i + 1}.webp`;
      }
    };

    preloadVideo();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (videoBoxRef?.current && containerRef?.current) {
        const containerOffsetTop = containerRef.current.offsetTop; // 최상단으로 부터 Layout의 상단까지의 길이
        const containerOffsetHeight = containerRef.current.offsetHeight; // Layout의 높이
        const scrollY = window.scrollY; // 현재 스크롤된 거리
        const windowHeight = window.innerHeight; // 현재 창의 높이
        const { height } = videoBoxRef.current.getBoundingClientRect(); // videoBox의 높이
        const stickyStartPosition = window.innerHeight / 2 - height / 2; // videoBox가 sticky로 고정되는 시작점
        const offset = 106 + 60 + 160 + height + stickyStartPosition + 48;
        // 106: headerHeight, 60: 타이틀의 높이, 160: Container 컴포넌트의 padding
        // height: videoBox의 높이, stickyStartPosition: videoBox가 sticky로 고정되는 시작점, 48: 타이틀의 하단 마진

        // sticky된 시점부터 sticky가 끝ㄴ는 시점까지 스크롤 비율 (0 ~ 1)
        const ratio =
          (scrollY + windowHeight - (containerOffsetTop + offset)) /
          (containerOffsetHeight - offset);

        // ratio가 0 ~ 1 사이일 때만 애니메이션을 진행해야 하지만 텍스트를 조금 일찍 등장시키고
        // 마지막 텍스트는 화면에서 사라지기 전에 유지시키기 위해 해당 수치로 진행
        if (ratio > -0.5 && ratio <= 1.5) {
          if (textRef && textRef.current) {
            TEXTS.forEach((item, index) => {
              const {
                animation: { startPosition, endPosition },
              } = item;
              const ref = textRef.current[index];
              const sectionRatio = (ratio - startPosition) / (endPosition - startPosition);
              if (startPosition <= ratio && ratio <= endPosition) {
                // 현재 애니메이션이 진행중인 인덱스의 video를 재생
                if (videoRef && videoRef.current && videoRef.current[index]) {
                  const video = videoRef.current[index]!;

                  if (video.currentTime === 0) {
                    setCurrentVideoIndex(index + 1);
                    video
                      .play()
                      .then(() => {
                        // video play success
                      })
                      .catch(err => {
                        console.error(err);
                      });
                  }
                }
                // 현재 진행중인 애니메이션의 진행률
                ref!.style.willChange = 'transform, opacity';
                const currentTransformValue = getValueFromRate(100, -100, yEase(sectionRatio));

                if (index === TEXTS.length - 1 || index === 0) {
                  if (
                    (!(currentTransformValue < 0) && index === TEXTS.length - 1) ||
                    (index === 0 && currentTransformValue < 0)
                  ) {
                    // 마지막 텍스트는 화면에서 사라지기 전에 유지
                    ref!.style.transform = `matrix(1, 0, 0, 1, 0, ${currentTransformValue})`;
                    ref!.style.opacity =
                      sectionRatio < 0.6
                        ? getValueFromRate(0.2, 1, ease(sectionRatio))
                        : getValueFromRate(1, 1, easeIn(sectionRatio));
                  }

                  if (index === 0 && currentTransformValue < 0) {
                    // 첫번째 텍스트 또한 화면에서 사라지기 전에 유지
                    ref!.style.transform = `matrix(1, 0, 0, 1, 0, ${currentTransformValue})`;
                    ref!.style.opacity =
                      sectionRatio > 0.6 && getValueFromRate(1, 0.2, easeIn(sectionRatio));
                  }
                } else {
                  ref!.style.transform = `matrix(1, 0, 0, 1, 0, ${currentTransformValue})`;
                  ref!.style.opacity =
                    sectionRatio < 0.6
                      ? getValueFromRate(0.2, 1, ease(sectionRatio))
                      : getValueFromRate(1, 0, easeIn(sectionRatio));
                }
              } else {
                // 현재 진행중인 애니메이션이 아니라면 video를 중지시키고 duration을 0으로 셋팅해서 다시 재생될 시 처음부터 재생되도록 함
                // 애니메이션이 끝난 텍스트는 will-change와 opacity를 초기화
                ref!.style.willChange = 'auto';
                ref!.style.opacity = '0';
                if (index === TEXTS.length - 1 && ratio > 1) {
                  // 마지막 텍스트는 화면에서 사라지기 전에 유지
                  ref!.style.transform = `matrix(1, 0, 0, 1, 0, 0)`;
                  ref!.style.opacity = '1';
                } else if (index === 0 && ratio < 0) {
                  ref!.style.transform = `matrix(1, 0, 0, 1, 0, 0)`;
                  ref!.style.opacity = '1';
                }
                if (videoRef && videoRef.current && videoRef.current[index]) {
                  const video = videoRef.current[index]!;
                  video.pause();
                  // 이전 동영상 fadeout 후 동영상 재생률을 0으로 설정
                  video.currentTime = 0;
                }
              }
            });
          }
        }
      }
    };
    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Layout ref={containerRef}>
      <Container
        containerCss={css`
          height: calc(300vh + 1080px);
          margin: 0 auto;
          padding: 160px 0;
        `}
      >
        <div
          css={css`
            position: sticky;
            top: 100px;
            ${media.md} {
              top: calc(50% - 180px);
            }
            display: flex;
            flex-direction: column;
            ${media.md} {
              flex-direction: row;
            }
            gap: 16px;
          `}
        >
          <ContentsWrapper ref={videoBoxRef}>
            <Font
              type="Heading2"
              style={css`
                position: absolute;
                bottom: 100%;
                margin: 0 24px;
                ${media.md} {
                  margin: 48px 0;
                }
                display: block;
                background: linear-gradient(90deg, #fffb9b 0%, #00ffc2 20.81%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                text-align: center;
                ${media.md} {
                  text-align: left;
                }
              `}
            >
              Token Message
            </Font>
            {Array.from({ length: 4 }).map((_, index) => {
              return (
                <video
                  key={index}
                  ref={ref => (videoRef.current[index] = ref)}
                  src={`/video/section3/adm-30f-${index + 1}.mp4`}
                  muted
                  playsInline
                  preload="metadata"
                  id="Section3-video"
                  css={css`
                    @keyframes fadeOut {
                      to {
                        opacity: 0;
                      }
                    }

                    @keyframes fadeIn {
                      from {
                        opacity: 0;
                      }
                      to {
                        opacity: 1;
                      }
                    }
                    // currentVideoIndex가 바뀔 시 현재 영상에서 다음 영상으로 자연스럽게 바뀌도록 애니메이션 진행시간을 다르게 적용
                    animation: ${currentVideoIndex === index + 1
                        ? 'fadeIn 0.5s ease-in'
                        : 'fadeOut 0s ease-out'}
                      forwards;
                  `}
                />
              );
            })}
          </ContentsWrapper>
          <TextSection ref={scrollRef}>
            {TEXTS.map((item, index) => {
              return (
                <TextWrapper ref={ref => (textRef.current[index] = ref)} key={index}>
                  <Font type="Strong1" textColor={item.color as ColorType}>
                    {item.title}
                  </Font>
                  <Font type="Display4">{item.description}</Font>
                </TextWrapper>
              );
            })}
          </TextSection>
        </div>
      </Container>
    </Layout>
  );
};

const ContentsWrapper = styled.div`
  position: relative;
  z-index: 2;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 95%;
  ${media.md} {
    padding-top: 50%;
  }
  ${media.md} {
    margin: 0 40px;
  }
  video {
    width: 100%;

    ${media.xs} {
      padding: 24px;
    }
    ${media.md} {
      max-width: 512px;
      padding: 0;
    }

    position: absolute;
    top: 0;
    left: 0;

    border-radius: 32px;
  }
`;

const TextSection = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 24px;
  ${media.lg} {
    margin: 0 40px;
  }
`;

const TextWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  width: 100%;
  ${media.md} {
    max-width: 512px;
  }
  height: 150px;
  z-index: 1;
  text-align: center;
  ${media.md} {
    height: 200px;
    text-align: left;
    padding-top: 45%;
  }

  max-height: 512px;
  opacity: 0;
`;

export default Section3;
