import { useState } from 'react';
// style
import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';
import { media } from 'Styles/neptoTheme';
// component
import Container from 'Components/Atoms/Container';
import AosComponent from 'Components/Atoms/AosComponent';
import Font from 'Components/Atoms/Font';
import Input from 'Components/Atoms/Input';
// util
import { validationEmailChecker } from 'Util/authChecker';
// api
import { registerEmail } from 'Api/email';

const EmailCollect = () => {
  const { colorTheme } = useTheme();
  const [email, setEmail] = useState('');
  const [inputHelperText, setInputHelperText] = useState('');
  const [isInputValid, setIsInputValid] = useState(true);

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setIsInputValid(validationEmailChecker(e.target.value).valid);
    setInputHelperText(validationEmailChecker(e.target.value).text);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isInputValid || email === '') return;
    try {
      const res = await registerEmail(email);
      const data = res.data;

      if (data.status) {
        setIsInputValid(true);
        setInputHelperText('Email has been successfully registered.');
      } else if (!data.status) {
        if (data.text === 'already subscripted') {
          setInputHelperText('This email has already been registered.');
          setIsInputValid(false);
        }
      } else {
        setIsInputValid(false);
        setInputHelperText('Email registration failed, please try again.');
      }
    } catch (e) {
      setIsInputValid(false);
      setInputHelperText('Email registration failed, please try again.');
    }
  };

  return (
    <div
      css={css`
        background-color: ${colorTheme.shade100};
        border-bottom: 1px solid ${colorTheme.shade200};
      `}
    >
      <Container
        containerCss={css`
          padding: 180px 24px;
        `}
      >
        <AosComponent>
          <Font
            as="h2"
            type="Display3"
            textColor="shade900"
            style={css`
              max-width: 413px;
              text-align: center;
              margin: 0 auto 56px;
              ${media.sm} {
                max-width: 553px;
              }

              ${media.md} {
                max-width: 709px;
              }

              ${media.lg} {
                max-width: 789px;
              }
            `}
          >
            Sign up to get notified with Nepto news.
          </Font>
          <Form onSubmit={onSubmit}>
            <Input
              containerStyle={css`
                max-width: 618px;
              `}
              name="email"
              css={css`
                width: 100%;
              `}
              value={email}
              onChange={onChangeEmail}
              maxLength={51}
              isError={!isInputValid}
              helperText={inputHelperText}
              placeholder="Enter your email address"
            />
            <Button type="submit">
              <Font type="Button">Sign Up</Font>
            </Button>
          </Form>
        </AosComponent>
      </Container>
    </div>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${media.sm} {
    flex-direction: row;
    justify-content: center;
  }
`;

const Button = styled.button`
  width: 100%;
  height: 64px;
  ${media.sm} {
    height: 84px;
  }
  padding: 14px 24px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colorTheme.primary100};
  transition: all 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.colorTheme.primary87};
  }

  &:active {
    scale: 0.9;
  }

  ${media.sm} {
    width: 160px;
  }
`;

export default EmailCollect;
